import * as React from 'react'
import { Navigate } from 'react-router-dom'

import Home from "./pages/Home";
import About from "./pages/About";
import Doctor from "./pages/Doctor";
import Subject from "./pages/Subject";
import Contacts from "./pages/Contacts";
import ChannelK from "./pages/ChannelK";
import ListGallery from "./pages/ListGallery";
import ListAccordion from "./pages/ListAccordion";
import ReadPost from "./pages/ReadPost";
import ReadVideo from "./pages/ReadVideo";
import TemporomandibularJoint from "./pages/TemporomandibularJoint";
import Petit from "./pages/Petit";
import WisdomToothExtraction from "./pages/WisdomToothExtraction";
import ImplantSurgeryAndProsthetics from "./pages/ImplantSurgeryAndProsthetics";
import ImplantBoneGraft from "./pages/ImplantBoneGraft";
import DentalMinorSurgery from "./pages/DentalMinorSurgery";
import ToothAndJawTrauma from "./pages/ToothAndJawTrauma";
import NaturalToothPreservationTreatment from "./pages/NaturalToothPreservationTreatment";
import PreventiveDentalTreatment from "./pages/PreventiveDentalTreatment";

export default [
  {
    name: "홈",
    path: "/",
    Component: Home,
 
  },
  {
    name: "치과소개",
    path: "/about",
    Component: About,
  },
  {
    name: "의료진소개",
    path: "/doctor",
    Component: Doctor,
  },
  {
    name: "진료과목",
    path: "/subject",
    Component: Subject,
  },
  {
    name: "진료과목 - 턱관절 질환",
    path: "/subject/temporomandibular-joint",
    Component: TemporomandibularJoint,
  },
  {
    name: "진료과목 - 쁘띠",
    path: "/subject/petit",
    Component: Petit,
  },
  {
    name: "진료과목 - 사랑니 등 고난이도 발치",
    path: "/subject/wisdom-tooth-extraction",
    Component: WisdomToothExtraction,
  },
  {
    name: "진료과목 - 임플란트 수술 및 보철",
    path: "/subject/implant-surgery-and-prosthetics",
    Component: ImplantSurgeryAndProsthetics,
  },
  {
    name: "진료과목 - 임플란트 치료를 위한 뼈이식술",
    path: "/subject/implant-bone-graft",
    Component: ImplantBoneGraft,
  },
  {
    name: "진료과목 - 치아 및 턱뼈 외상",
    path: "/subject/dental-minor-surgery",
    Component: DentalMinorSurgery,
  },
  {
    name: "진료과목 - 치과 소수술",
    path: "/subject/tooth-and-jaw-trauma",
    Component: ToothAndJawTrauma,
  },
  {
    name: "진료과목 - 자연치를 보존하는 치료",
    path: "/subject/natural-tooth-preservation-treatment",
    Component: NaturalToothPreservationTreatment,
  },
  {
    name: "진료과목 - 예방치과치료",
    path: "/subject/preventive-dental-treatment",
    Component: PreventiveDentalTreatment,
  },
  {
    name: "채널K",
    path: "/channel-k",
    Component: ChannelK,
  },
  {
    name: "채널K - 영상 콘텐츠",
    path: "/channel-k/video/list/:page",
    Component: ListGallery,
  },
  {
    name: "채널K - 영상 콘텐츠",
    path: "/channel-k/video/read/:id",
    Component: ReadVideo,
  },
  {
    name: "채널K - 블로그 포스트",
    path: "/channel-k/blog/list/:page",
    Component: ListGallery,
  },
  {
    name: "채널K - 블로그 포스트",
    path: "/channel-k/blog/read/:id",
    Component: ReadPost,
  },
  {
    name: "치과자료",
    path: "/channel-k/archive/:page",
    element: <ListAccordion board="archive" title="치과 자료" description="필요한 자료가 있으시면 다운받아 이용하세요." />
  },
  {
    name: "자주묻는 질문",
    path: "/channel-k/faq/:page",
    element: <ListAccordion board="faq" title="자주묻는 질문" description="환자분들이 많이 물어보시는 질문들을 모아놨습니다." />,
  },
  {
    name: "채널K - 영상 콘텐츠",
    path: "/channel-k/video",
    element: <Navigate to="/channel-k/video/list/1" replace />
  },
  {
    name: "채널K - 영상 콘텐츠",
    path: "/channel-k/video/list",
    element: <Navigate to="/channel-k/video/list/1" replace />
  },
  {
    name: "채널K - 블로그 포스트",
    path: "/channel-k/blog",
    element: <Navigate to="/channel-k/blog/list/1" replace />
  },
  {
    name: "채널K - 블로그 포스트",
    path: "/channel-k/blog/list",
    element: <Navigate to="/channel-k/blog/list/1" replace />
  },
  {
    name: "채널K - 치과 자료",
    path: "/channel-k/archive",
    element: <Navigate to="/channel-k/archive/1" replace />
  },
  {
    name: "채널K - 자주묻는 질문",
    path: "/channel-k/faq",
    element: <Navigate to="/channel-k/faq/1" replace />
  },
  {
    path: "/contacts",
    Component: Contacts,
  },
];