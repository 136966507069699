import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axiosInstance from "../axiosInstance";

import { Button, Container, Spinner } from "react-bootstrap";

import Arrows from "../components/Arrows";
import Horizontal from "../components/Horizontal";
import Gallery from "../components/Gallery";
import ShareModal from "../components/ShareModal";

interface LatestApiResponse {
  meta: {
    limit: number;
    perview: number;
    page: number;
    total: number;
  };
  items: [{
    id: number;
    board_id: number;
    title: string;
    content: string;
    attach: number;
    thumb: number;
    youtube: string;
    writer: number;
    date: string;
    board_name: string;
    thumb_path: string;
    writer_nickname: string;
  }];
}

function ReadPost() {
  const { id } = useParams();
  const [data, setData] = useState<BoardPostGetResponse | null>(null);
  const [galleryLatest, setGalleryLatest] = useState<LatestApiResponse | any>(null);

  useEffect(() => {
    // API에서 게시글 데이터 불러오기
    axiosInstance.get(`${process.env.REACT_APP_API_BASE_URL}/board/post/get/${id}`) // API 응답 구조를 명시적으로 지정
      .then(response => {
        setData(response.data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [id]); // 아이디 값이 변경 혹은 할당될 때 불러옴

  useEffect(() => {
    // API에서 포스트 현황 4개 불러오기
    axiosInstance.post(`${process.env.REACT_APP_API_BASE_URL}/board/post/list/1`, {
        limit: 4,
        board_id: [1, 2],
      })
      .then(response => setGalleryLatest(response.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []); // 컴포넌트가 로드될 때 불러옴

  return (
    <>
      <Helmet>
        <title>케이 구강악안면외과 치과의원 - 채널K - 블로그 포스트</title>
      </Helmet>

      {data ?
        <Container className="channelk">

          {/* Blog Article */}
          <article className="channelk-blog">

            {/* Post Metadata */}
            <div className="meta">
              <div>
                <dl>
                  <dt>카테고리</dt>
                  <dd>{data.board_name}</dd>

                  <dt>작성일</dt>
                  <dd>{data.date}</dd>
                </dl>
              </div>
            </div>
            
            {/* Post Thumb */}
            <div className="thumb">
              {data.thumb ?
                <img src={data.thumb} alt="대표 썸네일" width="100%" />
                :
                <>
                  {data.youtube && (
                    <img src={`https://img.youtube.com/vi/${data.youtube.split('/')[data.youtube.split('/').length - 1]}/sddefault.jpg`} alt="" width="100%" />
                  )}
                </>
              }
            </div>

            {/* Post Title */}
            <h1 className="title">{data.title}</h1>

            {/* Post Content */}
            <div className="content ck-content" dangerouslySetInnerHTML={{__html: data.content}}></div>

            {/* Attach */}
            {data.attach.path && data.attach.originalname && (
              <div className="attach">
                <h3 className="d-none">첨부파일</h3>
                  <ul>
                    <li>
                      <Link to={data.attach.path} download={data.attach.originalname}>{data.attach.originalname}</Link>
                    </li>
                  </ul>
              </div>
            )}

            {/* Control Pannel */}
            <div className="control">

              {/* Profile */}
              {data.writer.profile && data.writer.nickname && data.writer.memo && (
                <div className="profile">
                  <figure>
                    {data.writer.profile && (
                      <div><img src={data.writer.profile} alt={data.writer.nickname + "프로필 이미지"} /></div>
                    )}
                    <figcaption>
                      <em>{data.writer.nickname}</em>
                      <span>{data.writer.memo}</span>
                    </figcaption>
                  </figure>
                </div>
              )}

              {/* Control Buttons */}
              <div className="buttons">
                <ShareModal text={data.title} href={window.location.href} />
              </div>

            </div>

            {/* Arrows */}
            <Arrows data={data.arrows} board_id={data.board_id} />

          </article>

        </Container>
        :
        <Container className="channelk">
          <div className="d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          </div>
        </Container>
      }

      <Horizontal />

      {/* Gallery */}
      <Gallery className="is-recent" items={galleryLatest?.items} col={4} firstEmphasis={false} />
    </>
  );
}

export default ReadPost;
