import React from "react";
import { Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

type Props = {
  className?: string | undefined
  href: string | undefined // 버튼 클릭시 이돌될 페이지번호 링크 형식(링크중 존재하는 {id}는 페이지 번호로 치환됨)
  limit: number | undefined // 페이지당 보여지는 게시글 수
  perview: number | undefined // 페이지네이션에 한번에 보여질 수 있는 최대 페이지 번호 개수
  page: number | undefined // 현재 페이지 번호
  total: number | undefined // 전체 게시글 수
}

function Numbering({className, href, limit, perview, page, total}: Props) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nowParams = location.search.length > 0 ? "?" + searchParams.toString() : "";

  if (href && limit && perview && page && total) {
    const last = Math.ceil(total / limit);
    const start = Math.floor((page % perview == 0) ? (page / perview - 1) : (page / perview)) * perview + 1;
    const end = Math.floor((page % perview == 0) ? (page / perview - 1) : (page / perview)) * perview + perview;
    const numbers: any = [];

    for (let i = start; i <= end && i <= last; i++) {
      numbers.push(
        <li><Link className={i === page ? "is-active" : ""} to={href.replace('{number}', i.toString()) + nowParams}>{i}</Link></li>
      );
    }

    return (
      <nav className={className ? "numbering " + className : "numbering"}>
        
        {page > 1 ?
          <Link to={href.replace('{number}', "1") + nowParams} title="처음">&lt;&lt;</Link>
          :
          <span>&lt;&lt;</span>
        }

        {(start - perview) > 0 ?
          <Link to={href.replace('{number}', (start - perview).toString()) + nowParams} title="이전">&lt;</Link>
          :
          <span>&lt;</span>
        }

        <ul>
          {numbers}
        </ul>

        {end < last ?
          <Link to={href.replace('{number}', (start + perview).toString()) + nowParams} title="다음">&gt;</Link>
          :
          <span>&gt;</span>
        }

        {page < last ?
          <Link to={href.replace('{number}', last.toString()) + nowParams} title="끝">&gt;&gt;</Link>
          :
          <span>&gt;&gt;</span>
        }
      </nav>
    );
  } else {
    return null;
  }
}

export default Numbering;
