import React from "react";
import { Button } from "react-bootstrap";

type Props = {
  children: React.ReactNode,
  term: string,
  description?: string,
  termClassName?: string,
  btn?: {
    text: string,
    href: string,
  },
};

function Definition({ children, term, termClassName, description, btn }: Props) {
  return (
    <div className="definition">
      <dl>
        <dt className={termClassName}>
          <div>
            {description && (
              <span className="definition-description">{description}</span>
            )}
            <em className="definition-term">{term}</em>
          </div>
          {btn && (
            <div>
              <Button className="text-white" href={btn.href} variant="primary">{btn.text}</Button>
            </div>
          )}
        </dt>
        <dd>{children}</dd>
      </dl>
    </div>
  );
}

export default Definition;
