import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import axiosInstance from "../axiosInstance";

import { Button, Card, Form } from "react-bootstrap";

interface ApiResponse {
  items: {
    id: number;
    attach: number;
    attach_path: string;
    alt: string;
    href: string;
    blank: boolean;
    display: boolean;
    close: boolean;
  }[];
}

const Popup = (props: any) => {
  const [data, setData] = useState<ApiResponse | any>(null);
  const [cookies, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    // API에서 데이터 불러오기
    axiosInstance.get(`${process.env.REACT_APP_API_BASE_URL}/popup/list/0`)
      .then(response => setData(response.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleExpire = (id: number) => {
    // 클릭 시, 현재 시간을 기준으로 24시간 후의 시간을 계산합니다.
    const expires = new Date();
    expires.setHours(expires.getHours() + 24);

    // 쿠키를 설정합니다.
    setCookie('popup_expire_' + id, true, { expires });
  };

  const handleClose = (id: number) => {
    // 해당하는 state에 close: true를 설정합니다.
    setData((prevData: any) => {
      return {
        items: prevData.items.map((item: any) =>
          item.id === id ? {
            ...item,
            close: true
          } : item
        ),
      };
    });
  }

  return (
    <aside>
      {data && data.items.map((item: any) => {
        return (item.attach_path && item.display && !item.close && !cookies['popup_expire_' + item.id]) ? (
          <Card key={item.id} id={`popup_${item.id}`} className={"popup " + props.className}>
            <Link to={item.href} target={item.target ? "_blank" : "_self"}>
              <img src={item.attach_path} alt={item.alt} />
            </Link>
            <Card.Footer className="d-flex justify-content-between align-items-center">
              <Form.Label className="d-flex mb-0">
                <Form.Check onClick={() => handleExpire(item.id)} />
                <Form.Text className="fs-6 m-0 ms-2">오늘 하루 더 이상 보지 않기</Form.Text>
              </Form.Label>
              <Button onClick={() => handleClose(item.id)} variant="outline-dark">닫기</Button>
            </Card.Footer>
          </Card>
        ) : (
          <></>
        )
      })}
    </aside>
  );
}

export default Popup;
