import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

const NavigationSubject = (props: any) => {
  const location = useLocation();
  const menuItems = useSelector((state: any) => state.menu);
  const channelItems = menuItems.find(({ href }: any) => href === "/subject").items;

  return (
    <nav className="subject-nav">
      <ul>
        {channelItems.map((item: any) => (
          <li
            key={item.id}
            className={location.pathname.indexOf(item.href) !== -1 ? "is-active" : ""}
          >
            <Link to={item.href}>{item.text}</Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavigationSubject;
