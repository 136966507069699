import React from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "react-bootstrap";

import Horizontal from "../components/Horizontal";
import NavigationSubject from "../components/NavigationSubject";

import TemporomandibularJoint01 from '../assets/images/temporomandibular-joint01.jpg';
import TemporomandibularJoint02 from '../assets/images/temporomandibular-joint02.jpg';
import TemporomandibularJoint03 from '../assets/images/temporomandibular-joint03.jpg';
import TemporomandibularJoint04 from '../assets/images/temporomandibular-joint04.jpg';
import TemporomandibularJoint05 from '../assets/images/temporomandibular-joint05.jpg';
import TemporomandibularJoint06 from '../assets/images/temporomandibular-joint06.jpg';
import TemporomandibularJoint07 from '../assets/images/temporomandibular-joint07.jpg';
import TemporomandibularJoint08 from '../assets/images/temporomandibular-joint08.jpg';
import TemporomandibularJoint09 from '../assets/images/temporomandibular-joint09.jpg';
import TemporomandibularJoint10 from '../assets/images/temporomandibular-joint10.jpg';

function TemporomandibularJoint() {
  return (
    <>
      <Helmet>
        <title>케이 구강악안면외과 치과의원 - 진료과목 - 턱관절 질환</title>
      </Helmet>

      <div className="subject">
        <Container>
          <Row className="vertical-wrap flex-wrap-xs flex-nowrap-lg">
            <Col xs="12" lg="5" xxl="4">
              <h1>턱관절 질환</h1>
              <p>상담부터 주사치료까지 턱관절 관련 질환은 맞춤형 치료가 중요합니다. 현대인의 급증하는 스트레스로 인해 다양한 턱관절질환들이 많이 발생하고 있습니다. 대부분 턱관절에 무리한 힘이 가해지는 것이 턱관절장애를 유발하는 주 원인입니다. 무리한 힘을 발생시키는 요인들은 외상, 치아 상실로 인해 음식 씹는 기능의 장애, 이갈이 혹은 이악물기와 같은 나쁜 습관, 부정교합, 정신적 스트레스 등 매우 다양합니다. 원인을 찾아서 정확히 진단하고 환자 개인별 맞춤형 치료를 시행하는 것이 매우 중요합니다.</p>
              <Row>
                <Col className="my-3" xs="12">
                  <img className="w-100" src={TemporomandibularJoint01} alt="턱관절 질환자 X-ray 사진" />
                </Col>
                <Col className="my-3" xs="6">
                  <img className="w-100" src={TemporomandibularJoint02} alt="턱관절 관련 장치" />
                </Col>
                <Col className="my-3" xs="6">
                  <img className="w-100" src={TemporomandibularJoint03} alt="턱관절 관련 장치" />
                </Col>
              </Row>
            </Col>

            <Col className="vertical"></Col>

            <Col xs="12" lg="7" xxl="8">
              <div id="row1">
                <h2>상담 및 행동요법</h2>
                <div>
                  <p>병인론 및 턱관절 관련 주의사항을 자세히 설명하고 나쁜 구강악습관을 교정하고 턱관절에 무리한 힘이 가해지지 않는 방법들을 상세히 설명하고 환자들이 잘 숙지해야 합니다.</p>
                </div>
              </div>

              <Horizontal top="40px" bottom="40px" left="0" right="0" />

              <div id="row2">
                <h2>약물치료</h2>
                <div>
                  <p>소염진통제, 근육이완제 등 적절한 약물들이 처방됩니다. 약물의 효능과 부작용을 고려하여 환자에게 잘 맞는 약물들을 복용해야 합니다.</p>
                </div>
              </div>

              <Horizontal top="40px" bottom="40px" left="0" right="0" />
              <div id="row3">
                <h2>스플린트(구강장치)치료</h2>
                <div>
                  <p>눈이 나쁜 사람들은 안경을 착용하고 걷기 힘든 사람들은 목발을 사용합니다. 턱관절이 나쁜 사람들은 맞춤형 장치를 사용해야 하는 경우가 많습니다. 턱관절과 안면 근육들의 긴장을 완화시키고 환자가 잘 인지하지 못하는 이갈이 혹은 이악물기와 같은 악습관을 교정하면 매우 유용한 효과를 보일 수 있습니다. 장치 비용은 법정 비급여이지만 케이 구강악안면외과치과의원에서는 환자들이 수긍할 수 있는 자동차보험 수가를 적용하고 있습니다.</p>
                </div>
              
                <Row className="mt-4">
                  <Col xs="12" md="6" lg="12" xl="6">
                    <img className="w-100" src={TemporomandibularJoint04} alt="스플린트(구강장치) 이미지" />
                  </Col>
                  <Col xs="12" md="6" lg="12" xl="6">
                    <img className="w-100" src={TemporomandibularJoint05} alt="스플린트(구강장치) 이미지" />
                  </Col>
                </Row>
              </div>

              <Horizontal top="40px" bottom="40px" left="0" right="0" />

              <div id="row4">
                <h2>물리치료</h2>
                <div>
                  <p>통증과 근육의 긴장을 완화시키기 위해 다양한 물리치료가 시행됩니다. 물리치료 비용은 모두 건강보험 적용을 받습니다.</p>
                </div>
                <div className="mt-4">
                  <img className="w-100" src={TemporomandibularJoint06} alt="스플린트(구강장치) 이미지" />
                </div>
              </div>

              <Horizontal top="40px" bottom="40px" left="0" right="0" />

              <Row id="row5" className="vertical-wrap flex-wrap-xs flex-nowrap-xxl">
                <Col xs="12" xxl="4">
                  <h2>주사치료</h2>
                  <div className="mb-4">
                    <p>증상에 따라 적절한 주사치료를 시행하면 매우 좋은 효과를 발휘합니다. 또한 치료기간을 현저히 단축시킬 수 있으며 부작용이 거의 없습니다.</p>
                  </div>
                </Col>

                <Col className="vertical d-none-xs d-block-xxl"></Col>
                
                <Col xs="12" xxl="8">
                  <dl>
                    <dt>스테로이드 주사</dt>
                    <dd className="mb-4">반복적으로 주사하면 부작용이 발생할 수 있기 때문에 정확한 진단이 이루어진 후 전문의의 판단에 따라 1-2회 주사가 시행됩니다.</dd>

                    <dt>히알루론산 주사</dt>
                    <dd className="mb-4">관절액 성분과 동일한 약물로서 반복적으로 투여해도 부작용이 거의 없으며 통증 및 염증 완화, 관절액 보충 등을 통해 턱관절의 기능을 정상화시킬 수 있는 좋은 약물입니다.</dd>
                    
                    <dt>보툴리눔독소 주사</dt>
                    <dd className="mb-4">
                      <img className="w-100 mt-2 mb-3" src={TemporomandibularJoint07} />
                      일반인들에게 “보톡스”로 알려져 있는 약물입니다. 근육이완 및 통증 완화 효과가 매우 좋습니다. 따라서 이갈이, 이악물기와 같은 구강악습관 교정으로 많이 사용되며 부가적으로 사각턱을 완화시키는 미용 효과를 얻을 수도 있습니다. 주사 비용은 비급여입니다.
                    </dd>

                    <dt>PDRN DNA 주사</dt>
                    <dd className="mb-4">상처치유를 촉진시키고, 혈관 및 조직 재생, 손상된 인대의 재생, 염증을 완화시키는 우수한 효과를 발휘합니다. 적절한 증례를 선택하여 주사할 경우 턱관절질환 치료에 큰 도움이 됩니다. 주사 비용은 비급여입니다. </dd>
                    
                    <dt>턱관절부 증식치료</dt>
                    <dd className="mb-4">
                      <Row>
                        <Col xs="7" sm="8" xl="7" xxl="8">포도당, 리도카인 마취제, 주사용 식염수를 적정 비율로 혼합하여 턱관절 주변의 인대, 건, 근육 부위에 주사함으로써 손상 받은 조직을 재생시키는 매우 유용한 치료법입니다. 신의료기술로 등재된 시술로서 비급여입니다.</Col>
                        <Col xs="5" sm="4" xl="5" xxl="4"><img className="mw-100" src={TemporomandibularJoint08} /></Col>
                      </Row>
                    </dd>
                    
                    <dt>턱관절세정술</dt>
                    <dd className="mb-4">
                      <Row>
                        <Col xs="6" xxl="7">턱관절 내부 공간에 바늘을 2개 삽입하여 하트만용액이나 생리식염수로 세척하여 관절강 내부의 염증성 물질들을 제거하는 치료입니다. 심한 염증으로 인해 턱관절 통증이 심하거나 입이 잘 안 벌어지는 환자들에서 매우 효과적인 치료법입니다.</Col>
                        <Col xs="6" xxl="5"><img className="mw-100" src={TemporomandibularJoint09} /></Col>
                      </Row>
                    </dd>
                    
                    <dt>발통점 주사</dt>
                    <dd className="mb-0">
                      <Row>
                        <Col xs="7" sm="8" xl="7" xxl="8">발통점은 팽팽한 밴드(taut band) 를 가진 국소적인 근육 통증이 존재하는 부위로서 이 부위에 주사를 시행하면 턱관절과 안면부 통증을 완화시킬 수 있다.</Col>
                        <Col xs="5" sm="4" xl="5" xxl="4"><img className="mw-100" src={TemporomandibularJoint10} /></Col>
                      </Row>
                    </dd>
                  </dl>
                </Col>
              </Row>
            </Col>
          </Row>

          <Horizontal top="40px" bottom="40px" />

          <NavigationSubject />
        </Container>
      </div>
    </>
  );
}

export default TemporomandibularJoint;
