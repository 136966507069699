import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import SearchChannelK from "./SearchChannelK";

const NavigationChannelK = (props: any) => {
  const location = useLocation();
  const menuItems = useSelector((state: any) => state.menu);
  const channelItems = menuItems.find(({ href }: any) => href === "/channel-k").items;

  return (
    <div className={"channelk-nav " + props.className}>
      <div className="title">
        <h1>CHANNEL.K</h1>
        <p>환자, 의사 모두에게 좋은 정보, 치과에 관한 모든 정보를 담다.</p>
      </div>
      <div className="filter">
        {/* 채널K 네비게이션 */}
        <nav className="nav">
          <ul>
            {channelItems.map((item: any) => (
              <li
                key={item.id}
                className={location.pathname.indexOf(item.href) !== -1 ? "is-active" : ""}
              >
                <Link to={item.href}>{item.text}</Link>
              </li>
            ))}
          </ul>
        </nav>

        {/* 채널K 검색바 */}
        <SearchChannelK />
      </div>
    </div>
  );
};

export default NavigationChannelK;
