import React from "react";
import { Link } from "react-router-dom";

type Props = {
  children: React.ReactNode,
  data: {
    key: number,
    text: string,
    href: string,
    target: string,
  }[],
};

function JumbotronLink({ children, data }: Props) {
  return (
    <section className="jumbotron-link">
      {children}
      
      {data && (
        <div className="link text-end">
          {data.map((item: any) => (
            <Link key={item.key} to={item.href} target={item.target}>{item.text}</Link>
          ))}
        </div>
      )}
    </section>
  );
}

export default JumbotronLink;
