import React from "react";
import { Helmet } from "react-helmet";
import { Button, Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";

import Definition from "../components/Definition";

import AboutOpening from "../assets/images/about-opening.jpg";
import AboutPhilosophy01 from "../assets/images/about-philosophy01.jpg";
import AboutPhilosophy02 from "../assets/images/about-philosophy02.jpg";
import AboutPhilosophy03 from "../assets/images/about-philosophy03.jpg";
import AboutPhilosophy04 from "../assets/images/about-philosophy04.jpg";
import AboutPhilosophy05 from "../assets/images/about-philosophy05.jpg";
import AboutPhilosophy06 from "../assets/images/about-philosophy06.jpg";
import AboutCircle01 from "../assets/images/about-circle01.jpg";
import AboutCircle02 from "../assets/images/about-circle02.jpg";
import AboutCircle03 from "../assets/images/about-circle03.jpg";
import AboutCircle04 from "../assets/images/about-circle04.jpg";
import IconMap from "../assets/images/icon-map.svg";
import Emblem from "../assets/images/emblem.svg";
import JumbotronLink from "../components/JumbotronLink";
import Horizontal from "../components/Horizontal";

function About() {
  return (
    <>
      <Helmet>
        <title>케이 구강악안면외과 치과의원 - 치과소개</title>
      </Helmet>

      <Container>
        <section className="about-opening">
          <h1>서로가 기대어 더 높은곳을 <br />바라볼 수 있는 목표를 지향합니다.</h1>
          <p>환자중심의 사고와 태도는 배려로 부터 시작 됩니다. 저희 케이 구강악안면외과 치과의원에서 해결하기 어려운 케이스는 <br />능력있고 우수한 전문가 또는 상급기관 이송을 통해 해결해 드리며, 저희가 해결할 수 있는 진료에 대해서는 최고의 진료를 약속드리겠습니다.</p>
          <img src={AboutOpening} alt="병원 사진" />
        </section>

        <Horizontal top="60px" />

        <section className="about-philosophy">
          <ul>
            <li>
              <Definition term="환자 중심 진료" description="">
                <div className="wrap">
                  <div className="thumb thumb-01"></div>
                  <div className="content">
                    <h4 className="title">저희의 약속은 <br />솔직하고 자랑스러운 <br />약속입니다.</h4>
                    <div className="text">
                      <p>
                        오직 환자의 입장에서 가장 최고의 결과만을 생각하고 행동하는 방법을 알고 있습니다.<br />
                        결코 자만하지 않고 배려하고 더불어사는 그런 의료 환경을 만드는 것에 앞장서겠습니다.
                      </p>
                    </div>
                  </div>
                </div>
              </Definition>
            </li>

            <Horizontal />

            <li>
              <Definition term="직원 존중" description="">
                <div className="wrap">
                  <div className="thumb thumb-02"></div>
                  <div className="content">
                    <h4>직원이 근무하기 <br />좋은 환경을 추구합니다.</h4>
                    <div>
                      <p>
                        케이구강악안면외과치과의원의 원장도 직원의 1명 입니다.<br />
                        직원이 행복하면 환자 중심 진료도 잘 이루어질 수 밖에 없습니다.
                      </p>
                    </div>
                  </div>
                </div>
              </Definition>
            </li>

            <Horizontal />

            <li>
              <Definition term="감염관리" description="">
                <div className="wrap">
                  <div className="thumb thumb-03"></div>
                  <div className="content">
                    <h4>감염관리는 <br />모든 의료기관들이 준수해야 하는 <br />가장 기본적인 것입니다.</h4>
                    <div>
                      <p>
                        위생은 기본중의 기본, 철저한 소독과 위생관리를 통해 환자에게 접하는 모든 기구들을 언제나 깨끗하고 위생적인 상태를 유지합니다.
                      </p>
                      <p>
                        기구 세척, 소독, 멸균, 건조, 포장은 국제적으로 공인된 시스템을 이용하여 완벽하게 이루어 지고 있습니다. 모든 출입문 에는 항바이러스 처리된 손잡이가 설치되어 있습니다.
                      </p>
                    </div>
                  </div>
                </div>
              </Definition>
            </li>

            <Horizontal />

            <li>
              <Definition term="정직한 진료" description="" btn={{
                text: "대표원장 소개 >",
                href: "/doctor"
              }}>
                <div className="wrap">
                  <div className="thumb thumb-04"></div>
                  <div className="content">
                    <h4>치료는 <br />환자도 납득되어야 합니다</h4>
                    <div>
                      <p>
                        환자가 납득할 수 있는 정직한 진료, 설명을 통해 환자 한분한분이 자신이 받는 치료를 이해하고 필요성을 느끼며 치료를 받아야
                        편한 마음으로 받을 수 있다는 것을 전문가로써 충분히 이해하고 있습니다.
                      </p>
                      <p>
                        케이 구강악안면외과 치과의원에서는 가능합니다.
                      </p>
                    </div>
                  </div>
                </div>
              </Definition>
            </li>

            <Horizontal />

            <li>
              <Definition term="환경관리" description="">
                <div className="wrap">
                  <div className="thumb thumb-05"></div>
                  <div className="content">
                    <h4>환자와 직원들이 <br />편안하게 쉬고 싶은 <br />내부 환경을 추구합니다.</h4>
                    <div>
                      <p>
                        검증된 공기 환기 및 청정시스템이 설치되어 있습니다.
                      </p>
                      <p>
                        치과 내부의 공기질을 측정할 수 있는 모니터링이 상시 가동되고 있으며 치과 진료 시 발생하는 비말과 분진, 공기중의 오염물질들이 없도록 철저하게 관리하고 있습니다.
                      </p>
                    </div>
                  </div>
                </div>
              </Definition>
            </li>

            <Horizontal />

            <li>
              <Definition term="안전관리" description="">
                <div className="wrap">
                  <div className="thumb thumb-06"></div>
                  <div className="content">
                    <h4>치과 내에서 발생할 수 있는 <br />응급상황이나 돌발사고에 <br />대처할 수 있는 준비가 되어 있습니다.</h4>
                    <div>
                      <p>
                        노약자, 전신질환을 보유한 환자들에서 응급상황이 발생할 경우 조치할 수 있는 응급장비 및 약품들이 비치되어 있으며, 대표원장과 직원들은 심폐소생술 등 응급구조 교육을 주기적으로 받고 있습니다.
                      </p>
                      <p>
                        화재 발생 시 대피할 수 있는 안전시스템도 잘 구축되어 있습니다.
                      </p>
                    </div>
                  </div>
                </div>
              </Definition>
            </li>
          </ul>
        </section>

        <Horizontal />

        <section className="about-button py-4">
          <Button className="btn-icon btn-icon-jumbo text-white" href="/contacts" variant="primary">
            <em>위치 및 진료시간 안내</em>
            <span className="ps-2"><img src={IconMap} alt="지도 아이콘" /></span>
          </Button>
        </section>

        <Horizontal />

        <section className="about-gallery py-4">
          <div className="grid">
            <div className="title">
              <div><img src={Emblem} alt="케이 구강악안면외과 치과의원" /></div>
              <h2 className="mt-4">케이 구강악안면외과 치과의원에 오신것을 환영합니다.</h2>
            </div>

            <div className="img-box img-box_01"></div>
            <div className="img-box img-box_02"></div>
            <div className="img-box img-box_03"></div>
            <div className="img-box img-box_04"></div>
            <div className="img-box img-box_05"></div>
            <div className="img-box img-box_06"></div>
            <div className="img-box img-box_07"></div>
            <div className="img-box img-box_08"></div>
            <div className="img-box img-box_09"></div>
            <div className="img-box img-box_10"></div>
            <div className="img-box img-box_11"></div>
          </div>

          <Swiper
            className="home-figure-swiper"
            slidesPerView={1}
            spaceBetween={24}
            breakpoints={{
              576: {
                slidesPerView: 2,
                pagination: {
                  clickable: true,
                }
              },
              768: {
                slidesPerView: 3,
                pagination: {
                  clickable: false,
                }
              },
              1260: {
                slidesPerView: 4,
                pagination: {
                  clickable: false,
                }
              },
            }}
          >
            <SwiperSlide className="img-box img-box_01"></SwiperSlide>
            <SwiperSlide className="img-box img-box_02"></SwiperSlide>
            <SwiperSlide className="img-box img-box_03"></SwiperSlide>
            <SwiperSlide className="img-box img-box_04"></SwiperSlide>
            <SwiperSlide className="img-box img-box_05"></SwiperSlide>
            <SwiperSlide className="img-box img-box_06"></SwiperSlide>
            <SwiperSlide className="img-box img-box_07"></SwiperSlide>
            <SwiperSlide className="img-box img-box_08"></SwiperSlide>
            <SwiperSlide className="img-box img-box_09"></SwiperSlide>
            <SwiperSlide className="img-box img-box_10"></SwiperSlide>
            <SwiperSlide className="img-box img-box_11"></SwiperSlide>
          </Swiper>
        </section>

        <Horizontal bottom="40px" />
        
        <section className="about-circle">
          <ul>
            <li>
              <figure>
                <div><img src={AboutCircle01} alt="철저한 위생관리 시스템" /></div>
                <figcaption>철저한 위생관리 시스템</figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <div><img src={AboutCircle02} alt="환자 중심의 섬세한 진료" /></div>
                <figcaption>환자 중심의 섬세한 진료</figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <div><img src={AboutCircle03} alt="체계적이고 편안한 관리" /></div>
                <figcaption>체계적이고 편안한 관리</figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <div><img src={AboutCircle04} alt="20년 이상의 경력과 노하우" /></div>
                <figcaption>20년 이상의 경력과 노하우</figcaption>
              </figure>
            </li>
          </ul>
        </section>

        <Horizontal top="40px" />

        <JumbotronLink data={[
            {
              key: 0,
              text: "네이버 예약",
              href: "https://booking.naver.com/booking/13/bizes/1089335",
              target: "_blank",
            },
          ]}
        >
          <h2 className="title">환자 중심의 진료와 치료, <br />믿을 수 있는 치과, <br />케이 구강악안면외과 치과의원 입니다.</h2>
        </JumbotronLink>
      </Container>
    </>
  );
}

export default About;
