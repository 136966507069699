import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../axiosInstance";

interface ApiResponse {
  id: number;
  banner_id: number;
  desktop: number;
  mobile: number;
  desktop_path: string;
  mobile_path: string;
  alt: string;
  href: string;
  blank: boolean;
}

function Banner(props: any) {
  const [data, setData] = useState<ApiResponse | any>(null);

  useEffect(() => {
    // API에서 데이터 불러오기
    axiosInstance.get(`${process.env.REACT_APP_API_BASE_URL}/banner/post/read/1`) // API 응답 구조를 명시적으로 지정
      .then(response => setData(response.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <>
      {data && (
        <section className={"banner " + props.className}>
          <Link to={data?.href} target={data?.target ? "_blank" : "_self"}>
            <img className="desktop" src={data?.desktop_path} alt={data?.alt} />
            <img className="mobile" src={data?.mobile_path} alt={data?.alt} />
          </Link>
        </section>
      )}
    </>
  );
}

export default Banner;
