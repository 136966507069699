import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import axiosInstance from "../axiosInstance";

import { Col, Container, Row } from "react-bootstrap";

import Gallery from "../components/Gallery";
import Banner from "../components/Banner";
import NavigationChannelK from "../components/NavigationChannelK";
import Horizontal from "../components/Horizontal";
import Widget from "../components/Widget";
import JumbotronLink from "../components/JumbotronLink";

interface ApiResponse {
  meta: {
    limit: number;
    perview: number;
    page: number;
    total: number;
  };
  items: [{
    id: number;
    board_id: number;
    title: string;
    content: string;
    attach: number;
    thumb: number;
    youtube: string;
    writer: number;
    date: string;
    board_name: string;
    thumb_path: string;
    writer_nickname: string;
  }];
}

function ChannelK() {
  const location = useLocation();
  const q = new URLSearchParams(location.search).get("q") as string;
  const [galleryLatest, setGalleryLatest] = useState<ApiResponse | null>(null);
  const [galleryRandom, setGalleryRandom] = useState<ApiResponse | null>(null);
  const [archiveLatest, setArchiveLatest] = useState<ApiResponse | null>(null);
  const [faqLatest, setFaqLatest] = useState<ApiResponse | null>(null);

  useEffect(() => {
    // API에서 최신 블로그 포스트 불러오기
    axiosInstance.post(`${process.env.REACT_APP_API_BASE_URL}/board/post/list/1`, {
        limit: 3,
        board_id: [1, 2],
        keyword: q && q != "" ? q : false,
      })
      .then(response => setGalleryLatest(response.data))
      .catch(error => console.error('Error fetching data:', error));

    // API에서 랜덤으로 블로그 포스트 불러오기
    axiosInstance.post(`${process.env.REACT_APP_API_BASE_URL}/board/post/list/1`, {
        limit: 12,
        board_id: [1, 2],
        random: true,
        keyword: q && q != "" ? q : false,
      })
      .then(response => setGalleryRandom(response.data))
      .catch(error => console.error('Error fetching data:', error));

    // API에서 랜덤으로 치과 자료실 포스트 불러오기
    axiosInstance.post(`${process.env.REACT_APP_API_BASE_URL}/board/post/list/1`, {
        limit: 8,
        board_id: [3],
        keyword: q && q != "" ? q : null,
      })
      .then(response => setArchiveLatest(response.data))
      .catch(error => console.error('Error fetching data:', error));

      // API에서 랜덤으로 치과 자료실 포스트 불러오기
      axiosInstance.post(`${process.env.REACT_APP_API_BASE_URL}/board/post/list/1`, {
          limit: 8,
          board_id: [4],
          keyword: q && q != "" ? q : null,
        })
        .then(response => setFaqLatest(response.data))
        .catch(error => console.error('Error fetching data:', error));
  }, [location]);

  return (
    <>
      <Helmet>
        <title>케이 구강악안면외과 치과의원 - 채널K</title>
      </Helmet>

      <Container className="channelk">
        {/* Navigation(Channel-K) */}
        <NavigationChannelK />

        <Horizontal />

        {/* 검색중이 아닐때 보여지는 요소 */}
        {!q || q == "" ? (
          <>
            {/* Gallery */}
            <Gallery className="is-latest" items={galleryLatest?.items} col={2} firstEmphasis={true} />

            {/* Banner */}
            <Horizontal top="40px" bottom="0" />
            <Banner />
            <Horizontal top="0" bottom="40px" />
          </>
        ) : (
          <></>
        )}

        {/* Gallery */}
        <Gallery className="is-latest" items={galleryRandom?.items} col={3} firstEmphasis={false} />

        {/* 검색중일 때 보여지는 요소 */}
        {q && q != "" ? (
          <>
            {/* Banner */}
            <Horizontal top="40px" bottom="0" />
            <Banner />
            <Horizontal top="0" bottom="40px" />
          </>
        ) : (
          <Horizontal bottom="40px" />
        )}

        <Row className="vertical-wrap flex-wrap-xs flex-nowrap-lg">
          <Col xs="12" lg="6" xl="5" xxl="4">
            {/* Archive */}
            <Widget data={archiveLatest?.items} title="치과 자료실" text="필요한 자료가 있으시다면 다운받아 이용하세요." href="/channel-k/archive/1" />
          </Col>

          <Col className="vertical"></Col>

          <Col xs="12" lg="6" xl="7" xxl="8">
            {/* FAQ */}
            <Widget data={faqLatest?.items} title="자주 묻는 질문" text="환자분들이 많이 물어보시는 질문들을 모아놨습니다." href="/channel-k/faq/1" />
          </Col>
        </Row>

        <Horizontal top="40px" bottom="0" />

        <JumbotronLink data={[
            {
              key: 0,
              text: "네이버 예약",
              href: "https://booking.naver.com/booking/13/bizes/1089335",
              target: "_blank",
            },
          ]}
        >
          <h2 className="title">진료 예약 및 상담</h2>
        </JumbotronLink>
      </Container>
    </>
  );
}

export default ChannelK;
