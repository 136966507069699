import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Arrows(props: any) {
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data)
  }, [props.data]);

  const board_name = () => {
    if (props.board_id == 1) {
      return "video";
    } else if (props.board_id == 2) {
      return "blog";
    } else {
      return "unknown";
    }
  }

  return (
    <>
      {data && (
        <div className="arrows">
          {data.prev.id ? 
            <Link className="arrows-prev" to={"/channel-k/" + board_name() + "/read/" + data.prev.id} title="이전 글 바로가기">
              <em>&lt; <i>이전글</i></em>
              <span>{data.prev.title}</span>
            </Link>
          :
            <div className="arrows-prev"></div>
          }

          <Link to="/" title="게시글 목록 으로 돌아가기"><em>목록으로</em></Link>

          {data.next.id ? 
            <Link className="arrows-next" to={"/channel-k/" + board_name() + "/read/" + data.next.id} title="다음 글 바로가기">
              <em><i>다음글</i> &gt;</em>
              <span>{data.next.title}</span>
            </Link>
          :
            <div className="arrows-next"></div>
          }
        </div>
      )}
    </>
  );
}

export default Arrows;
