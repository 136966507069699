import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axiosInstance from "../axiosInstance";

import { Accordion, Button, Container, ListGroup, Nav } from "react-bootstrap";

import NavigationChannelK from "../components/NavigationChannelK";
import Horizontal from "../components/Horizontal";
import Banner from "../components/Banner";
import Numbering from "../components/Numbering";

interface ApiResponse {
  meta: {
    limit: number;
    perview: number;
    page: number;
    total: number;
  };
  items: [{
    id: number;
    board_id: number;
    title: string;
    content: string;
    attach: number;
    thumb: number;
    youtube: string;
    writer: number;
    date: string;
    board_name: string;
    attach_path: string;
    attach_originalname: string;
    thumb_path: string;
    writer_nickname: string;
  }];
}

interface BoardApiResponse {
  id: number;
  name: string;
  role_read: number;
  role_form: number;
  role_read_name: string;
  role_form_name: string;
  category: string[];
}

type Props = {
  board: String
  title: String
  description: String
}

function ListAccordion({board, title, description}: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const q = new URLSearchParams(location.search).get("q") as string;
  const category = new URLSearchParams(location.search).get("category") as string;
  const { page } = useParams();
  const [accordionData, setAccordionData] = useState<ApiResponse | any>(null);
  const [boardData, setBoardData] = useState<BoardApiResponse | any>(null);
  const lastHash = useRef('');

  const handleCategoryButtonClick = (value?: string) => {
    const searchParams = new URLSearchParams(location.search);
    const urlArr = location.pathname.split("/");

    if (value) {
      searchParams.set('category', value);
    } else {
      searchParams.delete('category');
    }

    // 다른 쿼리 파라미터는 건들지 않았을 경우를 위해 기존 쿼리 파라미터를 유지합니다.
    const updatedParams = searchParams.toString();

    if (location.pathname.startsWith("/channel-k/archive")) {
      navigate(`/channel-k/archive/1?${updatedParams}`);
    } else if (location.pathname.startsWith("/channel-k/faq")) {
      navigate(`/channel-k/faq/1?${updatedParams}`);
    }
  };

  useEffect(() => {
    // 라우트가 현재 치과 자료 일 때 API로 보낼 파라미터
    if (location.pathname.startsWith("/channel-k/archive")) {
      // 치과자료 게시판 게시글 API 호출
      axiosInstance.post(`${process.env.REACT_APP_API_BASE_URL}/board/post/list/${page}`, {
        limit: 12,
        board_id: [3],
        keyword: q && q != "" ? q : null,
        category: category,
      })
        .then(response => setAccordionData(response.data))
        .catch(error => console.error('Error fetching data:', error));

      // 게시판 정보 API 호출
      axiosInstance.get(`${process.env.REACT_APP_API_BASE_URL}/board/manage/read/3`)
        .then(response => setBoardData(response.data))
        .catch(error => console.error('Error fetching data:', error));
    }

    // 라우트가 현재 자주묻는 질문 일 때 API로 보낼 파라미터
    else if (location.pathname.startsWith("/channel-k/faq")) {
      // 자주묻는 질문 게시판 게시글 API 호출
      axiosInstance.post(`${process.env.REACT_APP_API_BASE_URL}/board/post/list/${page}`, {
        limit: 12,
        board_id: [4],
        keyword: q && q != "" ? q : null,
        category: category,
      })
        .then(response => setAccordionData(response.data))
        .catch(error => console.error('Error fetching data:', error));
      
      // 게시판 정보 API 호출
      axiosInstance.get(`${process.env.REACT_APP_API_BASE_URL}/board/manage/read/4`)
        .then(response => setBoardData(response.data))
        .catch(error => console.error('Error fetching data:', error));
    }
  },[location]);

  
  useEffect(() => {
    // 라우터 해시값 확인하여 앵커 기능 처리(일치하는 항목 펼치기)
    const accordionClick = (id: any) => {
      const element = document.querySelector(`#${id} .accordion-button`);
      if (element) {
        const event = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
        });
        element.dispatchEvent(event);
      }
    };

    if (location.hash) {
      lastHash.current = location.hash.slice(1);
      accordionClick(lastHash.current);
    }
  },[accordionData]);

  const titleStyle = {
    paddingLeft: 0,
    paddingRight: 0,
  };

  return (
    <>
      <Helmet>
        <title>케이 구강악안면외과 치과의원 - 채널K - {location.pathname.startsWith("/channel-k/video") ? "영상 콘텐츠" : "블로그 포스트"}</title>
      </Helmet>

      <Container className="channelk">

        {/* Navigation(Channel-K) */}
        <NavigationChannelK />

        <Horizontal top="0" bottom="40px" />

        {/* Title */}
        <div className="widget">
          <div className="title" style={titleStyle}>
            <h2>{title}</h2>
            <p>{description}</p>
          </div>
        </div>

        <Horizontal bottom="10px" />

        {/* Category Tab */}
        {boardData?.category.length > 0 && (
          <Nav className="nav-default mt-5" justify variant="tabs">
            <Nav.Item>
              <Nav.Link
                className={!category ? "active" : ""}
                onClick={() => handleCategoryButtonClick()}
              >
                전체
              </Nav.Link>
            </Nav.Item>

            {boardData?.category.map((item: any) => (
              <Nav.Item key={item}>
                <Nav.Link
                  className={category === item ? "active" : ""}
                  onClick={() => handleCategoryButtonClick(item)}
                >
                  {item}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        )}

        {/* Accordion */}
        <Accordion className="is-custom">
          {accordionData?.items.map((item: any) => (
            <Accordion.Item id={"accordion_item_" + item.id} key={item.id} eventKey={item.id.toString()}>
              {/* Post Title */}
              <Accordion.Header>{item.title}</Accordion.Header>

              <Accordion.Body>
                {/* Post Content */}
                <div dangerouslySetInnerHTML={{__html: item.content }}></div>

                {/* Attach */}
                {item && item.attach_path ?
                  <div className="attach">
                    <h3 className="d-none">첨부파일</h3>
                      <ul>
                        <li>
                          <Link to={item.attach_path} download={item.attach_originalname}>{item.attach_originalname}</Link>
                        </li>
                      </ul>
                  </div>
                  :
                  <></>
                }
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>

        <Horizontal top="10px"  bottom="40px" />

        <Numbering href={"/channel-k/" + board + "/{number}"} limit={accordionData?.meta.limit} perview={accordionData?.meta.perview} page={accordionData?.meta.page} total={accordionData?.meta.total} />
        
        <Horizontal top="40px" bottom="40px" />

      </Container>
    </>
  );
}

export default ListAccordion;
