import React from "react";

import Hamburger from "./Hamburger";
import Navigation from "./Navigation";
import SocialMedia from "./SocialMedia";

import { Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

function Header() {
  const location = useLocation();

  return (
    <header className="l-header">
      <Container>
        {/* Header Logo */}
        <Link className="l-header-logo" to="/"></Link>

        {/* Hamburger Menu Button */}
        <Hamburger />

        {/* Global Navigation */}
        <Navigation className="l-header-nav" />

        {/* Header SNS List */}
        <SocialMedia className="l-header-sns" type="icon" />
      </Container>
    </header>
  );
}

export default Header;
