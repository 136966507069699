import React from "react";

type TimelineItemProps = {
  date: any,
  children: React.ReactNode
};
  
function TimelineItem({ date, children }: TimelineItemProps) {
  return (
    <>
      <dt>{date}</dt>
      <dd>{children}</dd>
    </>
  );
}

export default TimelineItem;
