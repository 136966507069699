import React from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "react-bootstrap";

import Horizontal from "../components/Horizontal";
import NavigationSubject from "../components/NavigationSubject";

import ImplantSurgeryAndProsthetics01 from '../assets/images/implant-surgery-and-prosthetics01.jpg';
import ImplantSurgeryAndProsthetics02 from '../assets/images/implant-surgery-and-prosthetics02.jpg';
import ImplantSurgeryAndProsthetics03 from '../assets/images/implant-surgery-and-prosthetics03.jpg';

function ImplantSurgeryAndProsthetics() {
  return (
    <>
      <Helmet>
        <title>케이 구강악안면외과 치과의원 - 진료과목 - 치과 임플란트 수술 및 보철</title>
      </Helmet>

      <div className="subject">
        <Container>
          <div>
            <h1>치과 임플란트 수술 및 보철</h1>
            <p>임플란트를 이용한 보철치료는 잇몸 속에 위치한 뼈 즉 치조골에 치과용 임플란트(인공치근, 인공치아)를 식립하여 상실된 치아의 기능과 심미성을 회복하는 치료입니다.</p>
          </div>

          <Horizontal top="60px" bottom="40px" />

          <Row id="row1">
            <Col xs="12" lg="7" xl="8" xxl="9">
              <h2>1 <br />임플란트 치료의 특징</h2>
              <p>오래전부터 상실된 치아들을 회복시키기 위해 고정성 보철치료와 가철성 보철치료(틀니)가 시행되어 왔습니다. 그러나 이런 치료법들과 비교할 때 임플란트 보철치료의 장점은 인접치를 손상시키지 않으며 치조골을 더 오래 유지시킬 수 있고, 음식을 훨씬 더 잘 씹을 수 있다는 것입니다. 임플란트 치료의 성공률은 매우 높지만, 실패하더라도 대개 기존의 보철치료 방법을 통해 해결할 수 있습니다. 그러나 치조골에 임플란트를 심기 위한 외과적 수술이 필수적이며 이와 관련된 다양한 합병증들이 발생할 수 있습니다.</p>
              <p>임플란트 수술 후 치유과정은 환자마다 다르므로 수술 방법과 치유기간, 보철치료 방법은 환자의 상태에 따라 다양합니다. 해부학적 특성(치조골의 양과 골질, 중요 해부학적 구조물과의 관계 등)에 따라 치료 계획의 변경, 골이식술과 같은 추가적인 치료, 다양한 생체재료들의 선택이 필요할 수 있고 치료 기간도 연장될 수 있습니다.</p>
            </Col>
            <Col xs="12" lg="5" xl="4" xxl="3">
              <img className="w-100" src={ImplantSurgeryAndProsthetics01} alt="임플란트 치료" />
            </Col>
          </Row>

          <Horizontal top="40px" bottom="40px" />

          <Row id="row2" className="vertical-wrap flex-wrap-xs flex-nowrap-lg">
            <Col xs="12" lg="4">
              <h2 className="mb-4">2 <br />일반적인 임플란트 치료 과정</h2>
            </Col>

            <Col className="vertical d-none-xs d-block-lg"></Col>

            <Col xs="12" lg="8">
              <div>
                <h3>진단</h3>
                <p>구강검사 및 방사선 검사, 모형 평가 등을 시행하여 치조골의 양과 골질 및 연조직 상태를 평가하여 임플란트 치료 가능성을 결정하게 됩니다. 임플란트 치료가 가능할 경우엔 추가적인 골이식과 연조직 처치의 필요성과 방법, 예상되는 치료기간 및 치료비용이 결정됩니다.</p>
              </div>

              <Horizontal top="40px" bottom="40px" left="0" right="0" />

              <div>
                <h3>수술</h3>
                <p>임플란트 수술 관련 합병증과 예상되는 치료비용에 대해 설명하고 환자 측의 동의를 얻은 후 수술이 시행됩니다. 수술 7~10일 후에 봉합사를 제거하고 주기적인 경과관찰이 이루어지게 됩니다. 경과관찰 기간 중에는 임플란트의 치유상태를 평가하기 위해 다양한 방사선사진이 촬영될 수 있습니다.</p>
              </div>

              <Horizontal top="40px" bottom="40px" left="0" right="0" />

              <Row className="vertical-wrap flex-wrap-xs flex-nowrap-lg">

                <Col xs="12" lg="6">
                  <ul className="list-disc h-100">
                    <li>
                      <h4>골이식과 임플란트 식립</h4>
                      <p>골량이 부족한 경우 다양한 골이식술이 동반됩니다. 치조골의 양과 골질, 골이식 방법에 따라 임플란트가 동시에 식립될 수도 있지만, 골이식만 시행하고 약 4~12개월의 치유기간을 거친 후 이차적으로 임플란트가 식립될 수도 있습니다.</p>
                      <img className="w-100" src={ImplantSurgeryAndProsthetics02} alt="골이식과 임플란트 식립" />
                    </li>
                  </ul>
                </Col>

                <Col className="vertical"></Col>

                <Col xs="12" lg="6">
                  <ul className="list-disc h-100">
                    <li className="d-flex flex-column h-100">
                      <h4>임플란트 식립 방법</h4>
                      <p>식립될 부위의 골상태에 따라 1회법(임플란트를 식립하고 상방에 치유나사를 연결한 후 의도적으로 노출시키는 방법) 혹은 2회법(임플란트를 식립하고 상방을 덮개나사로 연결한 후 창상을 완전히 봉합하는 방법)으로 식립될 수 있습니다. 2회법으로 식립한 경우엔 일정 치유기간을 거친 후 임플란트를 노출시키는 2차수술과정이 필요합니다.</p>
                      <img className="w-100 mt-auto" src={ImplantSurgeryAndProsthetics03} alt="임플란트 식립 방법" />
                    </li>
                  </ul>
                </Col>
              </Row>

              <Horizontal top="40px" bottom="40px" left="0" right="0" />

              <div>
                <h3>임플란트 보철</h3>
                <p>일반적으로 상악은 수술 후 6개월, 하악은 3개월의 치유기간을 거친 후 보철치료가 시작됩니다. 그러나 환자의 연령 및 건강상태, 식립된 임플란트의 초기 안정도(primary stability), 골이식과 연조직 처치 유무, 골질 등에 따라 치유기간이 더 짧아지거나 더 길어질 수도 있습니다.</p>
              </div>

              <Horizontal top="40px" bottom="40px" left="0" right="0" />

              <div>
                <h3>임플란트 유지관리</h3>
                <p>상부 보철물이 완성된 후 치과에서 지정해 주는 날짜에 반드시 내원해서 정기 검진 및 관리를 받으셔야 합니다. 초기에는 3개월 간격으로 내원하시다가 임플란트가 안정되는 시점인 보철 기능 1년 이후부터는 6개월 간격으로 내원하시면 됩니다.</p>
              </div>

            </Col>
          </Row>

          <Horizontal top="40px" bottom="40px" />

          <NavigationSubject />
        </Container>
      </div>
    </>
  );
}

export default ImplantSurgeryAndProsthetics;
