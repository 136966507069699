import React from "react";

import { Link } from "react-router-dom";

function ButtonArrow(props: any) {
  return (
    <Link to={props.href} className="btn btn-primary px-4 py-3 rounded-0 text-white">{props.text} <span className="ps-2">&gt;</span></Link>
  );
}

export default ButtonArrow;
