import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axiosInstance from "../axiosInstance";

import { Button, Col, Container, Row, Spinner } from "react-bootstrap";

import Arrows from "../components/Arrows";
import Horizontal from "../components/Horizontal";
import Gallery from "../components/Gallery";

import ShareModal from "../components/ShareModal";

interface LatestApiResponse {
  meta: {
    limit: number;
    perview: number;
    page: number;
    total: number;
  };
  items: [{
    id: number;
    board_id: number;
    title: string;
    content: string;
    attach: number;
    thumb: number;
    youtube: string;
    writer: number;
    date: string;
    board_name: string;
    thumb_path: string;
    writer_nickname: string;
  }];
}

function ReadVideo() {
  const { id } = useParams();
  const [data, setData] = useState<BoardPostGetResponse | null>(null);
  const [galleryLatest, setGalleryLatest] = useState<LatestApiResponse | any>(null);

  useEffect(() => {
    // API에서 게시글 데이터 불러오기
    axiosInstance.get(`${process.env.REACT_APP_API_BASE_URL}/board/post/get/${id}`) // API 응답 구조를 명시적으로 지정
      .then(response => {
        setData(response.data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [id]); // 아이디 값이 변경 혹은 할당될 때 불러옴

  useEffect(() => {
    // API에서 포스트 현황 4개 불러오기
    axiosInstance.post(`${process.env.REACT_APP_API_BASE_URL}/board/post/list/1`, {
        limit: 4,
        board_id: [1, 2],
      })
      .then(response => setGalleryLatest(response.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []); // 컴포넌트가 로드될 때 불러옴

  return (
    <>
      <Helmet>
        <title>케이 구강악안면외과 치과의원 - 채널K - 영상 콘텐츠</title>
      </Helmet>

      {data ?
        <Container className="channelk">

          {/* Blog Article */}
          <article className="channelk-video">

            <Row className="vertical-wrap flex-wrap-xs flex-nowrap-lg">

              <Col xs="12" lg="6" xl="7" xxl="8">
                {data.youtube ?
                  <div className="iframe">
                    <iframe src={`https://www.youtube.com/embed/${data.youtube.split('/')[data.youtube.split('/').length - 1]}`} title="대표 유튜브 동영상"></iframe>
                  </div>
                  :
                  <div className="iframe"></div>
                }
              </Col>

              <Col className="vertical"></Col>

              <Col xs="12" lg="6" xl="5" xxl="4">
                {/* Post Title */}
                <h1 className="title">{data.title}</h1>

                {/* Post Metadata */}
                <dl className="meta">
                  <dt>Date</dt>
                  <dd>{data.date}</dd>

                  <dt>Board Name</dt>
                  <dd>{data.board_name}</dd>

                  <dt>Content</dt>
                  <dd><div className="content ck-content" dangerouslySetInnerHTML={{__html: data.content}}></div></dd>
                </dl>

                {/* Attach */}
                {data.attach.path && data.attach.originalname && (
                  <div className="attach">
                    <h3 className="d-none">첨부파일</h3>
                      <ul>
                        <li>
                          <Link to={data.attach.path} download={data.attach.originalname}>{data.attach.originalname}</Link>
                        </li>
                      </ul>
                  </div>
                )}

                {/* Control Pannel */}
                <div className="control mb-0">

                  {/* Profile */}
                  <div className="profile">
                    <figure>
                      {data.writer.profile && (
                        <div><img src={data.writer.profile} alt={data.writer.nickname + "프로필 이미지"} /></div>
                      )}
                      <figcaption>
                        <em>{data.writer.nickname}</em>
                        <span>{data.writer.memo ? data.writer.memo : ""}</span>
                      </figcaption>
                    </figure>
                  </div>

                  {/* Control Buttons */}
                  <div className="buttons">
                    <ShareModal text={data.title} href={window.location.href} />
                  </div>

                </div>
              </Col>

            </Row>

            {/* Arrows */}
            <Arrows data={data.arrows} board_id={data.board_id} />

          </article>
        </Container>
        :
        <Container className="channelk">
          <div className="d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          </div>
        </Container>
      }

      <Horizontal />

      {/* Gallery */}
      <Gallery className="is-recent" items={galleryLatest?.items} col={4} firstEmphasis={false} />
    </>
  );
}

export default ReadVideo;
