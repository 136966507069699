import React, { useState } from 'react';
import { Button, Modal } from "react-bootstrap";

import IconShareURL from '../assets/images/icon-share-url.png';
import IconShareNaver from '../assets/images/icon-share-naver.png';
import IconShareTwitter from '../assets/images/icon-share-twitter.png';
import IconShareFacebook from '../assets/images/icon-share-facebook.png';

type Props = {
  text: string,
  href: string,
}

function ShareModal(props: Props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleURLShare = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      alert("클립보드에 링크가 복사되었습니다.");
    } catch (err) {
      console.log(err);
    }
  };

  const handleNaverShare = (text: string, url: string) => {
		let href = "https://share.naver.com/web/shareView?url=" +  encodeURIComponent(url) + "&title=" + encodeURIComponent(text);
		window.open(href, "BlogShare", "width=400, height=450, scrollbars=yes, resizable=yes");
	};
  
	const handleFacebookShare = (url: string) => {
		let href = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(url);	
		window.open(href, "FacebookShare", "width=640, height=340, scrollbars=yes, resizable=yes");
	};
	
	const handleTwitterShare = (text: string, url: string) => {
		let href = "https://twitter.com/intent/tweet?text=" + encodeURIComponent(text) + "&url="+ encodeURIComponent(url);
		window.open(href, "TwitterShare", "width=600, height=455, scrollbars=yes, resizable=yes");
	};

  return (
    <>
      <Button variant="outline-dark" onClick={handleShow}>공유하기</Button>
      <Modal aria-labelledby="share_modal" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="share_modal">
            공유하기
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-share">
            <li>
              <a onClick={() => handleURLShare(props.href)}>
                <img src={IconShareURL} alt="URL" />
              </a>
            </li>
            <li>
              <a onClick={() => handleNaverShare(props.text, props.href)}>
                <img src={IconShareNaver} alt="네이버" />
              </a>
            </li>
            <li>
              <a onClick={() => handleFacebookShare(props.href)}>
                <img src={IconShareFacebook} alt="페이스북" />
              </a>
            </li>
            <li>
              <a onClick={() => handleTwitterShare(props.text, props.href)}>
                <img src={IconShareTwitter} alt="트위터" />
              </a>
            </li>
          </ul>
          <p className="text-center mt-3 mb-0">공유하실 플랫폼을 선택해주세요</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="text-white" onClick={handleClose}>닫기</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ShareModal;