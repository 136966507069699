import React from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "react-bootstrap";

import Horizontal from "../components/Horizontal";
import NavigationSubject from "../components/NavigationSubject";

import PreventiveDentalTreatment01 from "../assets/images/preventive-dental-treatment01.jpg";
import PreventiveDentalTreatment02 from "../assets/images/preventive-dental-treatment02.jpg";

function PreventiveDentalTreatment() {
  return (
    <>
      <Helmet>
        <title>케이 구강악안면외과 치과의원 - 진료과목 - 예방치과치료</title>
      </Helmet>

      <div className="subject">
        <Container>
          <Row>
            <Col id="row1" xs="12" lg="6">
              <h1>예방치과치료</h1>
              <p>모든 질병의 최상의 치료법은 예방입니다. 충치, 잇몸질환, 구강암, 턱관절질환 등도 예방에 관심을 갖는다면 잘 관리할 수 있습니다.</p>
              <p>환자들 스스로 구강위생관리를 철저히 해야하며 정기적으로 치과에서 전문적인 예방치과관리를 받는다면 치아 상실을 최소화하고 다양한 구강질환들을 예방함으로써 삶의 질을 증가시킬 것입니다. 예방치과관리는 환자들의 구강상태에 따라 3개월, 6개월, 1년 주기로 시행될 수 있습니다. 전문적인 예방치과관리 비용은 다음과 같습니다.</p>
            </Col>

            <Col id="row3" xs="12" lg="6">
              <table className="table-primary">
                <colgroup>
                  <col width="33.333333%" />
                  <col width="33.333333%" />
                  <col width="33.333333%" />
                </colgroup>
                <thead className="text-center">
                  <tr>
                    <th scope="col" colSpan={3}>보험 스케일링 연1회</th>
                  </tr>
                  <tr>
                    <th scope="col" colSpan={3}>불소도포 및 치아 홈메우기</th>
                  </tr>
                  <tr>
                    <th scope="col">예방프로그램 A</th>
                    <th scope="col">예방프로그램 B</th>
                    <th scope="col">예방프로그램 C</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ul className="list-disc">
                        <li>스케일링</li>
                        <li>치아활택술 및 색소 제거</li>
                        <li>전문가 구강위생관리 및 교육</li>
                      </ul>
                    </td>
                    <td>
                      <ul className="list-disc">
                        <li>예방프로그램A</li>
                        <li>칫솔, 치간칫솔 등 구강위생용품 제공 및 사용법 설명</li>
                      </ul>
                    </td>
                    <td>
                      <ul className="list-disc">
                        <li>예방프로그램B</li>
                        <li>구강프로바이오틱스 1개월분 제공</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td className="price">80,000원</td>
                    <td className="price">120,000원</td>
                    <td className="price">160,000원</td>
                  </tr>
                </tfoot>
              </table>
            </Col>
          </Row>

          <div className="mt-5">
            <img className="w-100 d-none-xs d-block-md" src={PreventiveDentalTreatment01} alt="" />
            <img className="w-100 d-block-xs d-none-md" src={PreventiveDentalTreatment02} alt="" />
          </div>
          
          <Horizontal top="40px" bottom="40px" />

          <NavigationSubject />
        </Container>
      </div>
    </>
  );
}

export default PreventiveDentalTreatment;
