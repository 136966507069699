import React from "react";

type TimelineProps = {
  children: React.ReactNode,
};

function Timeline({ children }: TimelineProps) {
  return (
    <div className="timeline">
      <dl>
        {children}
      </dl>
    </div>
  );
}

export default Timeline;
