import React from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "react-bootstrap";

import Horizontal from "../components/Horizontal";
import NavigationSubject from "../components/NavigationSubject";

import ImplantBoneGraft01 from '../assets/images/implant-bone-graft01.png';
import ImplantBoneGraft02 from '../assets/images/implant-bone-graft02.png';
import ImplantBoneGraft03 from '../assets/images/implant-bone-graft03.png';
import ImplantBoneGraft04 from '../assets/images/implant-bone-graft04.png';
import ImplantBoneGraft05 from '../assets/images/implant-bone-graft05.png';
import ImplantBoneGraft06 from '../assets/images/implant-bone-graft06.png';
import ImplantBoneGraft07 from '../assets/images/implant-bone-graft07.png';
import ImplantBoneGraft08 from '../assets/images/implant-bone-graft08.png';

function ImplantBoneGraft() {
  return (
    <>
      <Helmet>
        <title>케이 구강악안면외과 치과의원 - 진료과목 - 임플란트 치료를 위한 뼈이식술</title>
      </Helmet>

      <div className="subject">
        <Container>
          <Row className="vertical-wrap flex-wrap-xs flex-nowrap-lg">
            <Col xs="12" lg="6" xl="5">
              <h1>임플란트 치료를 위한 뼈이식술</h1>
              <p>모든 임플란트는 충분한 골량이 확보된 부위에 안전하고 성공적으로 식립될 수 있습니다. 그러나 골량이 부족한 경우에는 다양한 종류의 골이식술이 필요합니다. 즉 건물을 건축할 때 하방의 단단한 기초공사가 잘 이루어져야 안전하고 튼튼한 건물이 만들어지는 것과 같습니다.</p>
            </Col>

            <Col className="vertical"></Col>

            <Col xs="12" lg="6" xl="7">
              <div id="row1">
                <h2>1 <br />발치 후 뼈이식술</h2>
                <p>발치한 부위는 시간이 경과하면서 연조직이 발치창으로 밀려들어가고 주변의 치조골 흡수가 진행됩니다. 발치창의 변화가 심할 것으로 예상되거나 발치한 부위의 골조직이 많이 파괴된 경우에는 심미적 및 기능적 수복과 임플란트 시술이 잘 이루어질 수 있도록 하기 위해 골이식을 시행하게 됩니다.</p>
              </div>

              <Horizontal top="40px" bottom="40px" left="0" right="0" />

              <div id="row2">
                <h2>2 <br />치조골의 국소적 결손부 수복을 위한 뼈이식술</h2>
                <p>임플란트를 식립할 부위에 골량이 부족한 경우가 많습니다. 골량 부족이 심한 경우에는 골이식을 우선 시행하고 일정 치유기간을 거친 후 임플란트를 식립하게 됩니다. 그러나 심하지 않다면 임플란트를 식립하면서 주변의 소규모 결손부에 골이식을 시행할 수도 있습니다. 이 술식은 치의학 용어로 골유도재생술(guided bone regeneration: GBR)로 불리고 있습니다.</p>
                <div>
                  <img className="w-100" src={ImplantBoneGraft01} alt="치조골의 국소적 결손부 수복을 위한 뼈이식술" />
                </div>
              </div>

              <Horizontal top="40px" bottom="40px" left="0" right="0" />

              <div id="row3">
                <h2>3 <br />치조골 증대술</h2>
                <p>치조골이 수직 또는 수평적으로 많이 부족한 경우에 시행되는 골이식술을 의미합니다. 증대시켜야 하는 양이 많기 때문에 자가골이식이 필요한 경우가 많으며, 수술의 침습도가 크고 치유 기간도 많이 소요됩니다.</p>
                <Row>
                  <Col><img className="w-100" src={ImplantBoneGraft02} alt="치조골 증대술 시술 전" /></Col>
                  <Col><img className="w-100" src={ImplantBoneGraft03} alt="치조골 증대술 시술 후" /></Col>
                </Row>
              </div>

              <Horizontal top="40px" bottom="40px" left="0" right="0" />

              <div id="row4">
                <h2>4 <br />상악동골이식술</h2>
                <p>상악 구치부에는 상악동이라는 해부학적 구조물이 있습니다. 상악 대구치가 소실되면 생리적인 골흡수가 불가피하고 상악동이 하방으로 확장되는 경향을 보입니다. 만약 치주질환이나 치아 주위에 큰 병소가 존재하여 발치한 경우에는 치조골의 소실량이 많기 때문에 임플란트를 식립할 수 있는 골량이 매우 부족하게 됩니다. 상악동골이식은 상악동점막을 노출시켜 상방으로 올리고 하방의 공간에 골이식재 또는 혈병으로 채워서 골량을 확보하는 술식을 의미합니다. 잔존골의 높이에 따라 상악동골이식과 동시에 임플란트를 식립하거나 골이식만 시행하고 일정 치유기간을 거친 후 이차적으로 임플란트를 식립할 수도 있습니다. 또한 잔존골의 양에 따라 임플란트를 식립하는 부위를 통해 상악동골이식술이 시행되거나 상악동 측방의 골벽을 제거한 후 상악동점막을 거상하면서 접근하는 방법이 수행될 수 있습니다.</p>
                <div>
                  <img className="w-100" src={ImplantBoneGraft04} alt="상악동골이식술" />
                </div>
              </div>

              <Horizontal top="40px" bottom="40px" left="0" right="0" />

              <div>
                <Row id="row5">
                  <Col>
                    <h2>* 뼈채취</h2>
                    <p>자가골이 필요한 경우 다음과 같은 부위에서 소량을 채취하여 사용할 수 있습니다.</p>
                    <p>
                      A. 하악골 정중앙 부위<br />
                      B. 하악골 골체부<br />
                      C. 하악골 우각부<br />
                      D. 근돌기(오훼돌기)<br />
                      E. 상악결절<br />
                      F. 상악골 전벽<br />
                      G. 광대뼈
                    </p>
                  </Col>
                  <Col>
                    <div>
                      <img className="w-100" src={ImplantBoneGraft05} alt="뼈채취 가능 부위 설명 이미지" />
                    </div>
                  </Col>
                </Row>
                <div className="mt-4">
                  <h3>뼈채취 과정</h3>
                  <Row>
                    <Col>
                      1
                      <img className="w-100" src={ImplantBoneGraft06} alt="뼈채취 과정 1번째" />
                    </Col>
                    <Col>
                      2
                      <img className="w-100" src={ImplantBoneGraft07} alt="뼈채취 과정 2번째" />
                    </Col>
                    <Col>
                      3
                      <img className="w-100" src={ImplantBoneGraft08} alt="뼈채취 과정 3번째" />
                    </Col>
                  </Row>
                </div>
              </div>
              <div>

              </div>
            </Col>
          </Row>

          <Horizontal top="40px" bottom="40px" />

          <NavigationSubject />
        </Container>
      </div>
    </>
  );
}

export default ImplantBoneGraft;
