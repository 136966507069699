import React from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "react-bootstrap";

import Horizontal from "../components/Horizontal";
import NavigationSubject from "../components/NavigationSubject";

import Petit01 from '../assets/images/petit01.jpg';
import Petit02 from '../assets/images/petit02.jpg';
import Petit03 from '../assets/images/petit03.jpg';
import Petit04 from '../assets/images/petit04.jpg';
import Petit05 from '../assets/images/petit05.jpg';
import Petit06 from '../assets/images/petit06.jpg';
import Petit07 from '../assets/images/petit07.jpg';
import Petit08 from '../assets/images/petit08.jpg';
import Petit09 from '../assets/images/petit09.jpg';
import Petit10 from '../assets/images/petit10.jpg';
import Petit11 from '../assets/images/petit11.jpg';
import Petit12 from '../assets/images/petit12.jpg';
import Petit13 from '../assets/images/petit13.jpg';
import Petit14 from '../assets/images/petit14.jpg';

function Petit() {
  return (
    <>
      <Helmet>
        <title>케이 구강악안면외과 치과의원 - 진료과목 - 쁘띠</title>
      </Helmet>

      <div className="subject">
        <Container>
          <Row className="vertical-wrap flex-wrap-xs flex-nowrap-lg">
            <Col xs="12" lg="6" xl="5" xxl="4">
              <h1>쁘띠</h1>
              <p>쁘띠 성형시술은 ‘약간’, ‘조금’이라는 뜻의 프랑스어 ‘쁘띠(petit)’를 접목시킨 용어로 보툴리눔 독소(botulinum toxin) 또는 필러(filler) 등 주사제를 이용하여 성형의 목적을 달성하는 최소 침습적인 치료를 의미합니다. 치과의사들은 합법적으로 안면쁘티 성형시술을 할 수 있습니다. 치과의사들은 주사치료에 매우 능숙하며 통증을 최소화하면서 시술할 수 있는 능력들을 보유하고 있습니다.</p>
              <Row>
                  <Col>
                    <img className="w-100" src={Petit01} alt="입술 증대술 시술 전" />
                  </Col>
                  <Col>
                    <img className="w-100" src={Petit02} alt="입술 증대술 시술 후" />
                  </Col>
                </Row>
            </Col>

            <Col className="vertical"></Col>

            <Col xs="12" lg="6" xl="7" xxl="8">
              <div id="row1" className="item">
                <h2>입가 주름치료</h2>
                <div>
                  <p>치과 보철, 임플란트 치료, 교정치료, 턱관절 치료, 턱교정 수술을 시행하면서 보조적으로 쁘띠 성형치료를 도입할 경우에 매우 좋은 치료효과를 얻을 수 있으며, 환자의 만족도도 더욱 향상될 것입니다. 예를 들어, 입술의 비대칭, 입술 두께 변화 및 입꼬리의 부자연스러움, 비순구(nasolabial sulcus) 함몰 또는 잇몸미소(gummy smile: 웃을 때 잇몸이 많이 드러나는 증상), 코 변형, 좌우 안면부 연조직 비대칭, 비심미적인 턱끝 모양(무턱 등) 등이 존재할 경우 보툴리눔 독소(botulinum toxin)와 필러를 이용하면 쉽고 빠르게 해결할 수 있습니다. 물론 치료 가능성 여부는 충분한 진찰과 상담 후 결정됩니다.</p>
                  <p>상 ․하악 의치를 사용하던 환자가 기능성 회복을 위하여 임플란트 치료를 하는 경우, 임플란트 보철물이 완성된 후 비순구와 입가 주름의 악화 및 입술의 얇아짐 등의 심미적 문제가 부각되는 경우를 종종 볼 수 있습니다. 이러한 경우에도 보툴리눔 독소와 필러를 이용한 쁘띠 성형으로 문제를 간단하게 해결할 수 있으며, 이는 치과치료의 효과를 높일 뿐 아니라 환자의 삶의 질도 향상시킬 수 있습니다.</p>
                </div>
              </div>

              <Horizontal top="40px" bottom="40px" left="0" right="0" />

              <div id="row2" className="item">
                <h2>입술 증대술</h2>
                <div>
                  <Row>
                    <Col>
                      <img className="w-100" src={Petit03} alt="입술 증대술 시술 전" />
                    </Col>
                    <Col>
                      <img className="w-100" src={Petit04} alt="입술 증대술 시술 후" />
                    </Col>
                  </Row>
                </div>
              </div>

              <Horizontal top="40px" bottom="40px" left="0" right="0" />

              <div id="row3" className="item">
                <h2>팔자주름 치료</h2>
                <div>
                  <Row>
                    <Col>
                      <img className="w-100" src={Petit05} alt="팔자주름 치료 전" />
                    </Col>
                    <Col>
                      <img className="w-100" src={Petit06} alt="팔자주름 치료 후" />
                    </Col>
                  </Row>
                </div>
              </div>

              <Horizontal top="40px" bottom="40px" left="0" right="0" />

              <div id="row4" className="item">
                <h2>입술 구각부 주름 치료</h2>
                <div>
                  <Row>
                    <Col>
                      <img className="w-100" src={Petit07} alt="입술 구각부 주름 치료 전" />
                    </Col>
                    <Col>
                      <img className="w-100" src={Petit08} alt="입술 구각부 주름 치료 후" />
                    </Col>
                  </Row>
                </div>
              </div>

              <Horizontal top="40px" bottom="40px" left="0" right="0" />

              <div id="row5" className="item">
                <h2>뺨 증대술</h2>
                <div>
                  <Row>
                    <Col>
                      <img className="w-100" src={Petit09} alt="뺨 증대술 시술 전" />
                    </Col>
                    <Col>
                      <img className="w-100" src={Petit10} alt="뺨 증대술 시술 후" />
                    </Col>
                  </Row>
                </div>
              </div>

              <Horizontal top="40px" bottom="40px" left="0" right="0" />

              <div id="row6" className="item">
                <h2>잇몸미소</h2>
                <div>
                  <Row>
                    <Col>
                      <img className="w-100" src={Petit11} alt="잇몸미소 시술 전" />
                    </Col>
                    <Col>
                      <img className="w-100" src={Petit12} alt="잇몸미소 시술 후" />
                    </Col>
                  </Row>
                </div>
              </div>

              <Horizontal top="40px" bottom="40px" left="0" right="0" />
              
              <div className="item">
                <h2>양쪽 교근비대증 치료</h2>
                <div id="row7">
                  <Row>
                    <Col>
                      <img className="w-100" src={Petit13} alt="양쪽 교근비대증 치료 전" />
                    </Col>
                    <Col>
                      <img className="w-100" src={Petit14} alt="양쪽 교근비대증 치료 후" />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>

          <Horizontal top="40px" bottom="40px" />

          <NavigationSubject />
        </Container>
      </div>
    </>
  );
}

export default Petit;
