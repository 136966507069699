import React from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "react-bootstrap";

import Horizontal from "../components/Horizontal";
import NavigationSubject from "../components/NavigationSubject";

import DentalMinorSurgery01 from "../assets/images/dental-minor-surgery01.jpg";
import DentalMinorSurgery02 from "../assets/images/dental-minor-surgery02.jpg";
import DentalMinorSurgery03 from "../assets/images/dental-minor-surgery03.png";
import DentalMinorSurgery04 from "../assets/images/dental-minor-surgery04.png";
import DentalMinorSurgery05 from "../assets/images/dental-minor-surgery05.jpg";

function DentalMinorSurgery() {
  return (
    <>
      <Helmet>
        <title>케이 구강악안면외과 치과의원 - 진료과목 - 치과소수술</title>
      </Helmet>

      <div className="subject">
        <Container>
          <Row className="vertical-wrap flex-wrap-xs flex-nowrap-lg">
            <Col xs="12" lg="6" xl="5">
              <h1>치과소수술</h1>
              <p>구강 내에는 다양한 병들이 발생할 수 있으며 기형적인 병소가 발생하기도 합니다. 구강악안면외과 전문의들은 이러한 병변들을 아주 쉽게 해결할 수 있습니다. 또한 병변의 크기가 매우 크거나 광범위한 수술이 필요할 경우 상급 의료기관 전문의에게 신속히 의뢰해 드립니다.</p>
            </Col>

            <Col className="vertical"></Col>

            <Col xs="12" lg="6" xl="7">
              <div id="row1">
                <h2>물혹 (낭종, cyst)</h2>
                <p>치아 주위 치조골이나 턱뼈에 물이 차는 일명 물혹(낭종)이 종종 발생하며 수술을 통해 제거하는 것이 원칙입니다. 그러나 증상이 없고 크기의 변화가 없는 경우엔 담당 주치의와 상의하여 주기적으로 경과를 관찰할 수도 있습니다.</p>
                <p>대부분의 물혹들은 임상증상과 방사선사진, 치아 및 치주조직 검사를 통해 쉽게 진단할 수 있다. 크기가 작은 경우에는 증상이 없는 경우가 대부분이지만 크기가 커지면서 치아 통증, 압통, 종창, 누공이 발생하고 방사선사진에서 경계가 명확한 병소가 잘 관찰됩니다.</p>
                <div className="mt-4">
                  <img className="w-100" src={DentalMinorSurgery01} alt="물혹" />
                </div>
                <div className="mt-3">
                  <img className="w-100" src={DentalMinorSurgery02} alt="물혹" />
                </div>
              </div>

              <Horizontal top="40px" bottom="40px" left="0" right="0" />

              <div id="row2">
                <h2>치근단절제술(Apicoectomy), 치근절단술(Root resection)</h2>
                <p>치근단농양, 치근 낭종과 같이 치근 첨부에 병소가 존재하는 경우에는 근관치료를 통해 치료하는 경우가 많습니다. 하지만 병소가 크거나 통상적인 근관치료에 잘 반응을 보이지 않는다면, 치근단 부위에 외과적으로 접근하여 병소를 완전히 제거하고 재발 방지를 위해 치근단의 일부를 절제한 후 역충전을 시행하는 데, 이것을 ‘치근단절제술’이라고 합니다. 그 외에도 다근치의 일부 치근에만 병소가 국한되어 있고 다른 치근들은 건전한 경우에는 이환된 부위의 치근만 절단하여 제거하는 ‘치근절단술’을 시행합니다.</p>
                <p>이 수술은 발치 전 치아를 보존하기 위한 최종 치료법입니다. 치료가 중단되거나 실패할 경우에는 발치를 시행하고, 통상적인 보철치료 또는 임플란트 치료가 필요할 것입니다. 실패를 감수하고 최대한 치아를 보존하고 싶은 욕구가 강한 환자분들이 선택할 수 있는 최종 치료법임을 잘 이해하시고 동의하셔야 합니다.</p>
                <Row className="mt-4">
                  <Col xs="auto" className="mw-50">
                    <img className="mw-100" src={DentalMinorSurgery03} alt="치근단절제술(Apicoectomy)" />
                  </Col>
                  <Col xs="auto" className="mw-50">
                    <img className="mw-100" src={DentalMinorSurgery04} alt="치근절단술(Root resection)" />
                  </Col>
                </Row>
              </div>

              <Horizontal top="40px" bottom="40px" left="0" right="0" />

              <div id="row3">
                <h2>소대절제술 (소대성형술, Frenectomy, Frenuloplasty)</h2>
                <p>소대(frenum)는 정상적으로 구강 내에 존재하는 구조물이며 혀 전방 아래에 위치한 설소대(lingual frenum), 상악 순측 중앙부와 협측에 위치하는 협순소대(buccal or labial frenum)가 대표적입니다. 그러나 비정상적으로 발육되거나 잘못된 부위에 위치할 경우에는 혀나 입술의 움직임 장애, 발음장애, 부정교합 등의 원인이 될 수 있습니다. 특히 임플란트 치료 시 소대가 치조정 근처에 위치할 경우에는 임플란트 보철물 협측의 치은퇴축 등을 유발하면서 구강위생관리 불량 혹은 심미적 문제점을 악화시킬 수 있습니다. 이러한 경우에는 소대를 제거하는 수술이 필요할 수 있습니다.</p>
              </div>

              <Horizontal top="40px" bottom="40px" left="0" right="0" />

              <div id="row4">
                <h2>치조골성형술 (Alveoloplasty)</h2>
                <p>발치 후 주변 치조골이 울퉁불퉁하거나 형태가 불량하여 상부 보철 치료에 지장을 초래할 경우 가지런하게 다듬어 주는 수술을 의미합니다.</p>
              </div>

              <Horizontal top="40px" bottom="40px" left="0" right="0" />

              <div id="row5">
                <h2>보철 혹은 교정치료에 필요한 수술</h2>
                <p>보철치료 혹은 교정치료가 잘 이루어질 수 있도록 보조적인 수술이 필요할 수 있습니다. 가령 잇몸을 잘 만들어 주거나 교정치료 시 치아들을 잘 움직일 수 있도록 나사를 뼈속에 삽입하는 수술 등이 필요하기도 합니다.</p>
              </div>

              <Horizontal top="40px" bottom="40px" left="0" right="0" />

              <div id="row6">
                <h2>치주치료</h2>
                <p>치주질환이란, 치아 주변 치은이 파괴되면서 치아가 흔들리게 되고 결국 빠지게 되는 염증성 질환입니다. 주 원인은 구강 내에 존재하는 유해 세균으로 알려져 있습니다. 치주치료는 파괴된 잇몸조직을 원상 복귀시키는 것이 아닙니다. 현 상태에서 질환이 진행되는 것을 최대한 억제하여 치아들의 상실 시기를 늦추기 위한 예방적 치료입니다. 스케일링은 가장 기본적인 치주치료의 일종이며 각종 구강질환을 방지하기 위한 예방적 치료 성격도 가지고 있습니다.</p>
                <p>치주질환이 심하면 심혈관질환, 뇌혈관질환, 치매, 당뇨 등 다양한 전신질환들을 더욱 악화시킬 수 있습니다. 따라서 예방적 잇몸관리를 잘하면 전신질환의 예방 및 관리에도 큰 도움이 될 수 있습니다.</p>
                <img className="w-100" src={DentalMinorSurgery05} alt="치주치료" />
              </div>
            </Col>
          </Row>

          <Horizontal top="40px" bottom="40px" />

          <NavigationSubject />
        </Container>
      </div>
    </>
  );
}

export default DentalMinorSurgery;
