import React from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "react-bootstrap";
import Slick from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import HomeHeroCaricature from '../assets/images/home-hero-caricature.png';
import DoctorOpening from '../assets/images/doctor-opening.png';
import DoctorGallery01 from '../assets/images/doctor-gallery01.png';
import DoctorGallery02 from '../assets/images/doctor-gallery02.png';
import DoctorGallery03 from '../assets/images/doctor-gallery03.png';
import DoctorGallery04 from '../assets/images/doctor-gallery04.png';
import DoctorGallery05 from '../assets/images/doctor-gallery05.png';
import DoctorGallery06 from '../assets/images/doctor-gallery06.png';
import DoctorGallery07 from '../assets/images/doctor-gallery07.png';
import DoctorGallery08 from '../assets/images/doctor-gallery08.png';
import DoctorGallery09 from '../assets/images/doctor-gallery09.png';
import DoctorGallery10 from '../assets/images/doctor-gallery10.png';
import DoctorGallery11 from '../assets/images/doctor-gallery11.png';
import DoctorGallery12 from '../assets/images/doctor-gallery12.png';
import DoctorGallery13 from '../assets/images/doctor-gallery13.jpg';
import DoctorGallery14 from '../assets/images/doctor-gallery14.jpg';
import DoctorGallery15 from '../assets/images/doctor-gallery15.jpg';
import DoctorGallery16 from '../assets/images/doctor-gallery16.jpg';
import DoctorGallery17 from '../assets/images/doctor-gallery17.jpg';

import Definition from "../components/Definition";
import JumbotronLink from "../components/JumbotronLink";
import Timeline from "../components/Timeline";
import TimelineItem from "../components/TimelineItem";
import Horizontal from "../components/Horizontal";

function About() {
  const DoctorGallery = [
    DoctorGallery01,
    DoctorGallery02,
    DoctorGallery03,
    DoctorGallery04,
    DoctorGallery05,
    DoctorGallery06,
    DoctorGallery07,
    DoctorGallery08,
    DoctorGallery09,
    DoctorGallery10,
    DoctorGallery11,
    DoctorGallery12,
    DoctorGallery13,
    DoctorGallery14,
    DoctorGallery15,
    DoctorGallery16,
    DoctorGallery17
  ]

  return (
    <>
      <Helmet>
        <title>케이 구강악안면외과 치과의원 - 의료진소개</title>
      </Helmet>
      <Container>
        <section className="doctor-opening">
          <Row>
            <Col className="title" xs="12" lg="6" xl="7">
              <img src={HomeHeroCaricature} height="160" alt="김영균 대표원장 캐리커쳐" />
              <h1><strong>김영균</strong> 대표원장</h1>
              <h2>서로가 서로에게 기대어 <br />더 높은 곳을 바라볼 수 있는 삶을 지향합니다.</h2>
              <p>
              치과의사, 치과위생사, 치과기공사, 치과관련 업체들이 협력하여 환자를 위한 최선의 진료를 수행합니다.
              </p>
            </Col>
            <Col className="image" xs="12" lg="6" xl="5">
              <img src={DoctorOpening} alt="김영균 대표 원장 사진" />
            </Col>
          </Row>
        </section>

        <Horizontal />
        
        <section className="doctor-definition">
          <ul>
            <li>
              <Definition term="학력" description="">
                <Timeline>
                  <TimelineItem date="1980. 3 ~ 1986. 2">서울대학교 치과대학 치의학과 치의학사</TimelineItem>
                  <TimelineItem date="1980. 3 ~ 1986. 2">서울대학교 치과대학 치의학과 치의학사</TimelineItem>
                  <TimelineItem date="1980. 3 ~ 1986. 2">서울대학병원 치과진료부 구강악안면외과 전공의</TimelineItem>
                  <TimelineItem date="1980. 3 ~ 1986. 2">서울대학교 대학원 치의학 석사 및 박사</TimelineItem>
                  <TimelineItem date="1980. 3 ~ 1986. 2">
                    <ul className="list-disc">
                      <li>석사: 하악관절돌기골절에 관한 임상적 연구</li>
                      <li>박사: 압열멸균처리한 이종골 이식 후 치유과정에 관한 실험적 연구</li>
                    </ul>
                  </TimelineItem>
                </Timeline>
              </Definition>
            </li>

            <Horizontal />

            <li>
              <Definition term="경력" description="">
                <Timeline>
                  <TimelineItem date="1986 ~ 1989">서울대학교병원 치과진료부 구강악안면외과 전공의</TimelineItem>
                  <TimelineItem date="1989. 4 ~ 1992. 4">대한민국 육군 치과군의관 복무 (육군 대위)</TimelineItem>
                  <TimelineItem date="1992. 5 ~ 1997. 3">조선대학교 치과대학 구강악안면외과 전임강사 및 조교수</TimelineItem>
                  <TimelineItem date="1998. 3 ~ 2003. 2">대진의료재단 분당제생병원 치과과장</TimelineItem>
                  <TimelineItem date="2003. 3 ~ 2024. 2">
                    <ul className="list-disc">
                      <li>분당서울대학교병원 치과 구강악안면외과 교수</li>
                      <li>서울대학교 치의학대학원 교수</li>
                    </ul>
                  </TimelineItem>
                  <TimelineItem date="2012. 11">EBS 턱관절질환 명의</TimelineItem>
                  <TimelineItem date="2014. 1">대한민국의학한림원 정회원</TimelineItem>
                </Timeline>
              </Definition>
            </li>

            <Horizontal />

            <li>
              <Definition term="수상" description="">
                <Timeline>
                  <TimelineItem date="1987. 12">서울대학교병원 의무기록 정리 모범 표창장</TimelineItem>
                  <TimelineItem date="1994. 10">대한악안면성형재건외과학회 표창장</TimelineItem>
                  <TimelineItem date="2000. 6">제5회 성남시검도회장배 검도대회 장년부 개인전 3위</TimelineItem>
                  <TimelineItem date="2000. 8">의료법인 대진의료재단 표창장</TimelineItem>
                  <TimelineItem date="2000. 10">사이버의학술대회 우수연재상 &lt;두개-안면 영역의 경조직 결손 수복을 위한 새로운 생체재료 개발치아석고&gt;</TimelineItem>
                  <TimelineItem date="2006. 12">분당서울대학교병원 혁신상</TimelineItem>
                  <TimelineItem date="2007. 12">분당서울대학교병원 훈남상</TimelineItem>
                  <TimelineItem date="2013. 4">성남시장배 생활체육 검도대회 중년부 준우승</TimelineItem>
                  <TimelineItem date="2013. 4">성남시장배 생활체육 검도대회 중년부 준우승, 단체전 3위</TimelineItem>
                  <TimelineItem date="2013. 10">분당서울대학교병원 표창장10년 근속</TimelineItem>
                  <TimelineItem date="2014. 4">제10회 대한치의학회 학술상 (연송치의학상)</TimelineItem>
                  <TimelineItem date="2014. 11">대한국제임플란트학회 최우수포스터상</TimelineItem>
                  <TimelineItem date="2015. 12">(사)대한턱관절협회 최우수 학술상</TimelineItem>
                  <TimelineItem date="2017. 12">2017 보건의료기술 진흥 유공자 보건복지부장관 표창</TimelineItem>
                  <TimelineItem date="2018. 6">제28회 과학기술우수논문상</TimelineItem>
                  <TimelineItem date="2022. 5">
                    <ul className="list-disc">
                      <li>대한구강악안면외과학회 공로패</li>
                      <li>학회지 편집위원장 공로패</li>
                    </ul>
                  </TimelineItem>
                  <TimelineItem date="2022. 10">분당서울대학교병원 모범직원 표창장</TimelineItem>
                  <TimelineItem date="2022. 12">성남시 검도회 30주년 공로상</TimelineItem>
                  <TimelineItem date="2023. 3">대한치과이식임플란트학회 학술대상</TimelineItem>
                  <TimelineItem date="2023. 5">분당서울대학교병원 표창장: 20년 장기 근속</TimelineItem>
                </Timeline>
              </Definition>
            </li>

            <Horizontal />

            <li>
              <Definition term="기타 대외활동" description="">
                <Timeline>
                  <TimelineItem date="1993. 8">광주 민주화운동 상이자 장애등급 검진 전문의</TimelineItem>
                  <TimelineItem date="2002 ~ 2006 2022 ~ 2024"> 국방부 의무자문관</TimelineItem>
                  <TimelineItem date="2002. 11 ~ 2009. 9">사단법인 대한턱관절협회 학술이사</TimelineItem>
                  <TimelineItem date="2007. 5 ~ 2013. 4">고려대학교 임상치의학대학원 외래교수</TimelineItem>
                  <TimelineItem date="2007. 6 ~ 2010. 6">치과의료전문평가위원회 위원: 보건복지부</TimelineItem>
                  <TimelineItem date="2007 ~ 2016">건강보험 진료심사평가위원회 비상근심사위원</TimelineItem>
                  <TimelineItem date="2011 ~ 2014">사단법인 대한턱관절협회 회장</TimelineItem>
                  <TimelineItem date="2012. 5 -2014. 4">첨단융복합 의료기기 외래전문가. 식품의약품안전청</TimelineItem>
                  <TimelineItem date="2013. 5 ~ 2015. 5">의료기기위원회 전문가. 식품의약품안전청</TimelineItem>
                  <TimelineItem date="2013. 11 ~ 2015. 11">중소기업기술개발 지원사업 평가위원. 한국산업기술평가관리원</TimelineItem>
                  <TimelineItem date="2013. 6. ~ 2022. 6">보건복지부 신의료기술평가위원회 분야별 전문평가위원회 위원. 보건복지부</TimelineItem>
                  <TimelineItem date="2014. 9 ~ 2016. 8">
                    <ul className="list-disc">
                      <li>식품의약품안전평가원 의료기기 허가 심사 자문 등을 위한 외부전문가.</li>
                      <li>식품의약품안전평가원</li>
                    </ul>
                  </TimelineItem>
                  <TimelineItem date="2016. 1 ~ 2017. 12">차세대 의료기기 100 프로젝트(맞춤형 멘토링) 전문가 위원. 식품의약품안전처</TimelineItem>
                  <TimelineItem date="2016. 9 ~ 2018. 8">의료기기 허가. 심사 자문 등을 위한 ‘임상전문위원’.식품의약품안전평가원</TimelineItem>
                  <TimelineItem date="2017. 10. 1">대한검도회 공인 4단</TimelineItem>
                  <TimelineItem date="2011 ~ 2024">대한구강악안면외과학회지 편집위원장</TimelineItem>
                  <TimelineItem date="2017 ~ 2020">대한치과이식임플란트학회 회장, 명예회장</TimelineItem>
                  <TimelineItem date="2019. 6 ~ 2021. 6">&lt;의료기기법&gt; 제5조에 의한 의료기기위원회 위원. 식품의약품안전처</TimelineItem>
                </Timeline>
              </Definition>
            </li>

            <Horizontal />

            <li>
              <Definition term="논문, 저서 및 진료 실적" description="2024년 2월 기준">
                <dl>
                  <dt className="h5">논문 실적</dt>
                  <dd className="mb-4">
                    <ul className="list-disc">
                      <li>Development of a novel bone grafting material using autogenous teeth. 외 718편</li>
                      <li>[SCI(E) 146편, KCI 등재지 283편, 기타국내학술지: 257편, 기타국제학술지: 32편]</li>
                    </ul>
                  </dd>

                  <dt className="h5">저서 실적</dt>
                  <dd className="mb-4">
                    <ul className="list-disc">
                      <li>임프란트의 모든 것 Q & A로 배운다. Vol. 1, 2외 89편</li>
                    </ul>
                  </dd>

                  <dt className="h5">임플란트 시술</dt>
                  <dd className="mb-0">
                    <ul className="list-disc">
                      <li>5,000명 이상 환자에서 15,000개 이상 임플란트 치료</li>
                      <li>대한치과이식임플란트학회 우수임플란트임상의. No. 0124</li>
                      <li>대한치과이식임플란트학회 인증의 No. 250</li>
                      <li>대한구강악안면임프란트학회 우수회원(2005. 07. 30), 우수회원번호: 73</li>
                    </ul>
                  </dd>
                </dl>
              </Definition>
            </li>
          </ul>
        </section>

        <Horizontal />

        <section className="doctor-gallery">
          <Slick dots={false} arrows={false} infinite={true} speed={300} variableWidth={true} slidesToShow={1} centerMode={false} autoplay={true}>
            {DoctorGallery.map((image, index) => (
              <div key={index}><img src={image} alt="" /></div>
            ))}
          </Slick>
        </section>

        <Horizontal />

        <JumbotronLink data={[
            {
              key: 0,
              text: "네이버 예약",
              href: "https://booking.naver.com/booking/13/bizes/1089335",
              target: "_blank",
            },
          ]}
        >
          <h2 className="title">원장님을 비롯한 유능한 치과의사들의 진료는 언제나 열려 있습니다.</h2>
          <div className="text">(진료시간 : 평일 9시 ~ 6시까지)</div>
        </JumbotronLink>
      </Container>
    </>
  );
}

export default About;
