import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { Container } from "react-bootstrap";
import Horizontal from "../components/Horizontal";

function Subject() {
  const location = useLocation();
  const menuItems = useSelector((state: any) => state.menu);
  const subjectItems = menuItems.find(({ href }: any) => href === "/subject").items;

  console.log(subjectItems);

  return (
    <>
      <Helmet>
        <title>케이 구강악안면외과 치과의원 - 진료과목</title>
      </Helmet>

      <Container>
        <div className="subject">
          <h1>케이 구강악안면외과 치과의원의 <br />진료과목을 소개합니다.</h1>

          <Horizontal top="40px" bottom="40px" />

          <nav className="subject-nav subject-nav-jumbo">
            <ul>
              <li><Link to="/subject/temporomandibular-joint">턱관절질환</Link></li>
              <li><Link to="/subject/petit">쁘띠</Link></li>
              <li><Link to="/subject/wisdom-tooth-extraction">사랑니 등 고난이도 발치</Link></li>
              <li><Link to="/subject/implant-surgery-and-prosthetics">임플란트 수술 및 보철</Link></li>
              <li><Link to="/subject/implant-bone-graft">임플란트 치료를 위한 뼈이식술</Link></li>
            </ul>
          </nav>

          <Horizontal top="40px" bottom="40px" />

          <nav className="subject-nav-detail">
            <ul>
              <li>
                <Link to="/subject/tooth-and-jaw-trauma">치아 및 턱뼈 외상</Link>
              </li>
              <li>
                <Link to="/subject/dental-minor-surgery">치과 소수술</Link>
                <ul>
                  <li><Link to="/subject/dental-minor-surgery#row1">물혹(낭종)</Link></li>
                  <li><Link to="/subject/dental-minor-surgery#row2">치근단절제술/치근절단술</Link></li>
                  <li><Link to="/subject/dental-minor-surgery#row3">소대절제술</Link></li>
                  <li><Link to="/subject/dental-minor-surgery#row4">치조골성형술</Link></li>
                  <li><Link to="/subject/dental-minor-surgery#row5">보철 혹은 교정치료에 필요한 수술</Link></li>
                  <li><Link to="/subject/dental-minor-surgery#row6">치주치료</Link></li>
                </ul>
              </li>
              <li>
                <Link to="/subject/natural-tooth-preservation-treatment">자연치를 보존하는 치료</Link>
                <ul>
                  <li><Link to="/subject/natural-tooth-preservation-treatment#row1">근관치료</Link></li>
                  <li><Link to="/subject/natural-tooth-preservation-treatment#row2">레진 수복치료</Link></li>
                  <li><Link to="/subject/natural-tooth-preservation-treatment#row3">치아이식술과 치아재식술</Link></li>
                </ul>
              </li>
              <li>
                <Link to="/subject/preventive-dental-treatment">예방치과치료</Link>
              </li>
            </ul>
          </nav>
        </div>
      </Container>
    </>
  );
}

export default Subject;
