import React from "react";
import { Helmet } from 'react-helmet'
import { useSelector } from "react-redux";

import "./assets/scss/style.scss";

import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
import Navigation from "./components/Navigation";

import RouteComponent from "./components/RouteComponent";
import { Container } from "react-bootstrap";

function App() {
  const isOpen = useSelector((state: any) => state.hamburger);

  return (
    <>
      <Helmet>
        <title>케이 구강악안면외과 치과의원</title>
      </Helmet>

      {/* Header */}
      <Header />

      {/* Global Navigation */}
      <Container className="w-100">
        <Navigation className={"l-navigation" + (isOpen ? " is-active" : "")} dropdown="true" />
      </Container>

      {/* Main */}
      <Main>
        <RouteComponent />
      </Main>

      {/* Footer */}
      <Footer />
    </>
  )
}

export default App;
