import React from "react";
import { Link } from "react-router-dom";
import Horizontal from "./Horizontal";

function Widget(props: any) {
  return (
		<div className={props.className ? "widget " + props.className : "widget"}>
			<div className="title">
				<h2><Link to={props.href}>{props.title} &gt;</Link></h2>
				<p>{props.text}</p>
			</div>
			<Horizontal left="0" right="0" />
			<div className="items">
				<ul>
					{props.data && props.data.map((item: any) => (
						<li key={item.id}>
							<Link to={`${props.href}#accordion_item_${item.id}`} title="게시글 바로가기">
								<em>{item.title}</em>
								<span>&gt;</span>
							</Link>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

export default Widget;
