import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SearchChannelK() {
  const location = useLocation();
  const q = new URLSearchParams(location.search).get("q") as string;
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState<string>(q);

  const handleKeywordChange = (e: any) => {
    setKeyword(e.target.value);
  }

  const handleKeywordSearch = () => {
    if (keyword == "" || keyword == null) {
      alert("키워드를 입력해주세요.");
    } else {
      navigate(location.pathname + '?q=' + keyword);
    }
  }

  const handleKeywordClear = () => {
    setKeyword("");
    navigate(location.pathname);
  }

  return (
    <div className="channelk-search">
      <div className="search">
        <input name="keyword" type="text" value={keyword} onChange={handleKeywordChange} placeholder="검색어를 입력하세요." />
        <button onClick={handleKeywordClear} disabled={keyword == "" || keyword == null ? true : false}>
          <FontAwesomeIcon icon={solid("filter-circle-xmark")} />
        </button>
        <button onClick={handleKeywordSearch}>
          <FontAwesomeIcon icon={solid("magnifying-glass")} />
        </button>
      </div>
    </div>
  );
};

export default SearchChannelK;
