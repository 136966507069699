import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Gallery(props: any) {
  const [items, setItems] = useState(props.items);

  useEffect(() => {
    setItems(props.items);
  }, [props.items])

  return (
    <div className={"gallery " + (props.className ? props.className : "")} data-col={props.col ? props.col : 1} data-firstemphasis={props.firstEmphasis ? props.firstEmphasis : false}>
      <ul>
        {items && items.map((item: any) => (
          <li key={item.id} data-thumb={item.thumb || item.youtube ? "true" : "false"}>
            <Link to={"/channel-k/" + (item.board_id == 1 ? "video" : "blog") + "/read/" + item.id} title="게시글 바로가기">
              <div className="thumb">
                {item && item.thumb_path ?
                  <img src={item.thumb_path} alt="대표 썸네일" width="100%" />
                  :
                  <>
                    {item && item.youtube ?
                      <img src={`https://img.youtube.com/vi/${item.youtube.split('/')[item.youtube.split('/').length - 1]}/sddefault.jpg`} alt="" width="100%" />
                      :
                      <></>
                    }
                  </>
                }
              </div>
              <div className="meta">
                <dl>
                  <dt>게시판명</dt>
                  <dd>{item.board_name}</dd>

                  <dt>날짜</dt>
                  <dd>{item.date}</dd>
                </dl>
              </div>
              <em className="title">{item.title}</em>
              <p className="summary">{item.content.replace(/<[^>]*>|&[^;]+;/g, '')}</p>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Gallery;
