import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Badge, Button, Modal, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

import JumbotronLink from "../components/JumbotronLink";
import Horizontal from "../components/Horizontal";

import ContactsModalImg from "../assets/images/contacts-modal.jpg";
import ContactsOpening from "../assets/images/contacts-opening.jpg";
import IconStethoscope from "../assets/images/icon-stethoscope.svg";

function MyVerticallyCenteredModal(props: any) {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          진료 수가표
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <img className="mw-100" src={ContactsModalImg} alt="진료수가표 이미지" />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="text-white" onClick={props.onHide}>닫기</Button>
      </Modal.Footer>
    </Modal>
  );
}

function Contacts() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Helmet>
        <title>케이 구강악안면외과 치과의원 - 진료시간 및 위치</title>
      </Helmet>

      <Container>
        <div className="contacts-image">
          <div><img src={ContactsOpening} alt="병원 사진" /></div>
        </div>

        <Horizontal />

        <section className="contacts-hour">
          <Row>
            <Col className="title" xs="12" lg="6">
              <div className="text">
                <h2> 진료시간 안내</h2>
                <p>케이 구강악안면외과 치과의원은 토요일, 일요일 및 공휴일은 휴진입니다</p>
              </div>
              <div className="description">
                <dl>
                  <dt>점심시간</dt>
                  <dd>13:00 ~ 14:00</dd>
                </dl>
                <p>점심시간은 오후 1시부터 2시까지 입니다.</p>
              </div>
            </Col>
            <Col className="week" xs="12" lg="6">
              <div>
                <ul>
                  <li>
                    <dl>
                      <dt>월요일</dt>
                      <dd>09:00 ~ 18:00</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>화요일</dt>
                      <dd>09:00 ~ 18:00</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>수요일</dt>
                      <dd>09:00 ~ 18:00</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>목요일</dt>
                      <dd>09:00 ~ 18:00</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>금요일</dt>
                      <dd>09:00 ~ 18:00</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>토요일, 일요일</dt>
                      <dd>휴무</dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </section>

        <Horizontal />

        <section className="py-4">
          <Button className="btn-icon btn-icon-jumbo text-white" variant="primary" onClick={() => setModalShow(true)}>
            <em>진료 수가표</em>
            <span className="ps-2"><img src={IconStethoscope} alt="지도 아이콘" /></span>
          </Button>
          <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </section>

        <Horizontal />

        <section className="contacts-direction">
          <h2>오시는 길</h2>
          <p>
            <span>경기 성남시 분당구 구미로 11 포인트타운빌딩, 5층</span>
            <Badge bg="primary">주차: 1시간 30분 무료 주차 등록</Badge>
          </p>
          <Row>
            <Col xs={12} md={6}>
              <div className="iframe">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3172.17295180785!2d127.10799817720476!3d37.33841393717383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b59e94e510ed1%3A0xd4f2160af179d525!2z6rK96riw64-EIOyEseuCqOyLnCDrtoTri7nqtawg6rWs66-466GcIDEx!5e0!3m2!1sko!2skr!4v1700212067871!5m2!1sko!2skr" width="100%" height="100%" title="구글지도"></iframe>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="image"></div>
            </Col>
          </Row>
        </section>

        <Horizontal />

        <JumbotronLink data={
          [
            {
              key: 0,
              text: "네이버 예약",
              href: "https://booking.naver.com/booking/13/bizes/1089335",
              target: "_blank",
            }
          ]
        }
        >
          <h2 className="title">진료 예약 및 상담</h2>
        </JumbotronLink>
      </Container>
    </>
  );
}

export default Contacts;
