import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function FooterModal(props: any) {
  return (
    <Modal
      {...props}
      aria-labelledby="footer-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="footer-modal">
          환자 권리와 의무
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        <div className="py-3">
          <h5>환자의 권리</h5>

          <div className="mt-4">
            <h6>존엄의 권리</h6>
            <p>환자는 존엄한 인간으로서 존중을 받을 권리가 있다.</p>
          </div>

          <div className="mt-4">
            <h6>평등의 권리</h6>
            <p>환자는 성별, 연력, 종교, 인종, 국적 또는 사회적 신분을 이유로 어떠한 차별도 받지 않으며, 성실한 진료와 의료시술을 받을 권리가 있다.</p>
          </div>

          <div className="mt-4">
            <h6>선택의 권리</h6>
            <p>환자는 치료, 검사, 수술, 입원등 의료행위에 대한 시행 여부를 선택할 권리가 있다.</p>
          </div>

          <div className="mt-4">
            <h6>설명을 들을 권리</h6>
            <p>환자는 의료진으로부터 질병의 진단, 치료계획, 결과, 예후에 대한 설명을 들을 권리가 있으며, 진료비 내역에 대해 알 권리가 있다.</p>
          </div>

          <div className="mt-4">
            <h6>비밀보장의 권리 권리</h6>
            <p>환자는 의무기록상의 진료내용과 신체 및 사생활의 비밀을 보장받을 권리가 있다.</p>
          </div>
        </div>
        <div className="py-3">
          <h5>환자의 의무</h5>
          <ol className="mt-4 ps-3">
            <li>치료계획을 준수한다.</li>
            <li>치료계획에 불응하여 발생된 결과에 대해 스스로 책임진다.</li>
            <li>병원 원내 규정을 준수한다.</li>
            <li>병원 직원 및 다른 환자를 존중하고 배려한다.</li>
            <li>병원과 체결된 재정적 의무에 대해 책임을 진다.</li>
          </ol>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="text-white" onClick={props.onHide}>닫기</Button>
      </Modal.Footer>
    </Modal>
  );
}

const NavigationFooter = (props: any) => {
  const menuFooterItems = useSelector((state: any) => state.menuFooter);
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <nav className={props.className}>
        <ul>
          <li>
            <span onClick={() => setModalShow(true)} style={{"cursor": "pointer"}}>환자 권리와 의무</span>
          </li>
          {menuFooterItems.map((item: any) => (
            <li key={item.id}>
              <Link to={item.href}>{item.text}</Link>
            </li>
          ))}
        </ul>
      </nav>

      <FooterModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default NavigationFooter;
