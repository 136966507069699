import React from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "react-bootstrap";

import Horizontal from "../components/Horizontal";
import NavigationSubject from "../components/NavigationSubject";

import ToothAndJawTrauma01 from "../assets/images/tooth-and-jaw-trauma01.png";
import ToothAndJawTrauma02 from "../assets/images/tooth-and-jaw-trauma02.jpg";
import ToothAndJawTrauma03 from "../assets/images/tooth-and-jaw-trauma03.jpg";
import ToothAndJawTrauma04 from "../assets/images/tooth-and-jaw-trauma04.jpg";
import ToothAndJawTrauma05 from "../assets/images/tooth-and-jaw-trauma05.jpg";

function ToothAndJawTrauma() {
  return (
    <>
      <Helmet>
        <title>케이 구강악안면외과 치과의원 - 진료과목 - 치아 및 턱뼈외상</title>
      </Helmet>

      <div className="subject">
        <Container>
          <Row>
            <Col xs="12" lg="6" xl="8">
              <h1>치아 및 턱뼈외상</h1>
              <p>교통사고, 폭행, 스포츠, 산재, 낙상, 등산 등을 비롯한 다양한 여가 및 일상 활동 중에 악안면 부위에 외상이 가해질 수 있으며, 크고 작은 연조직 손상뿐만 아니라 치아 및 치조골, 상 ․하악골, 광대뼈, 코뼈, 눈 주위 뼈 등의 골절이 발생하고 있습니다. 외상 후에는 응급의료기관을 통해 치과 구강악안면외과로 의뢰되거나 직접 치과병원에서 치과의사들의 진료를 받게 되는 경우도 많이 있습니다. 구강악안면외과전문의는 기본적인 응급처치뿐만 아니라 연조직과 경조직 외상의 진단 및 치료 개념을 가지고 있습니다. 필요한 경우엔 상급의료기관에서 잘 진료 받을 수 있도록 조치해 드립니다.</p>
              <p className="mb-5">모든 질환의 최상의 치료법은 예방입니다. 치아 및 턱뼈 외상을 최소화하기 위해 스포츠 혹은 생활체육활동을 할 경우 구강내 보호장치 (스포츠마우스가드)를 착용하는 습관을 갖는 것이 중요합니다.</p>
            </Col>
            <Col xs="12" lg="6" xl="4">
              <img className="w-100" src={ToothAndJawTrauma01} alt="치아 및 턱뼈외상" />
            </Col>
          </Row>

          <Horizontal top="40px" bottom="40px" />
          
          <Row className="vertical-wrap flex-wrap-xs flex-nowrap-lg">
            <Col xs="12" lg="4">
              <img className="w-100" src={ToothAndJawTrauma02} alt="빠진 치아를 원위치 시킨 후 고정한 모습" />
              <p className="mt-3">빠진 치아를 원위치 시킨 후 고정한 모습</p>
            </Col>
            
            <Col className="vertical"></Col>

            <Col xs="12" lg="8">
              <Row>
                <Col xs="12" lg="6">
                  <img className="w-100" src={ToothAndJawTrauma03} alt="파절된 치아를 레진으로 수복하기 전" />
                </Col>
                <Col xs="12" lg="6">
                  <img className="w-100" src={ToothAndJawTrauma04} alt="파절된 치아를 레진으로 수복한 후" />
                </Col>
              </Row>
              <p className="mt-3">파절된 치아를 레진으로 수복한 모습</p>
            </Col>
          </Row>

          <Horizontal top="40px" bottom="40px" />

          <Row>
            <Col className="my-2" xs="12" lg="4">
              <img className="w-100" src={ToothAndJawTrauma05} alt="스포츠마우스가드" />
            </Col>
            <Col className="my-2" xs="12" lg="8">
              <p>“스포츠마우스가드”라는 장치로서 탄력성 있는 재료로 환자의 구강에 맞춰서 제작합니다. 운동선수들은 자신의 치아들과 턱관절을 외상으로부터 보호하기 위해 착용하고 있으며 경기력을 향상시킨다는 연구 보고도 있습니다.</p>
              <p>특히 자전거, 롤러스케이트, 스키, 스노우보드 등을 즐기는 일반인들도 적극적으로 착용할 것을 권고합니다.</p>
            </Col>
          </Row>

          <Horizontal top="40px" bottom="40px" />

          <NavigationSubject />
        </Container>
      </div>
    </>
  );
}

export default ToothAndJawTrauma;
