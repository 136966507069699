import { createSlice } from "@reduxjs/toolkit";

const initialState = [
  {
    id: 0,
    text: "치과소개",
    href: "/about",
    active: false,
  },
  {
    id: 1,
    text: "의료진소개",
    href: "/doctor",
    active: false,
  },
  {
    id: 2,
    text: "진료과목",
    href: "/subject",
    active: false,
    items: [
      {
        id: 21,
        text: "턱관절질환",
        href: "/subject/temporomandibular-joint",
        active: false,
        items: [
          {
            id: 211,
            text: "상담 및 행동요법",
            href: "/subject/temporomandibular-joint#row1",
          },
          {
            id: 212,
            text: "약물치료",
            href: "/subject/temporomandibular-joint#row2",
          },
          {
            id: 213,
            text: "스플린트(구강장치)치료",
            href: "/subject/temporomandibular-joint#row3",
          },
          {
            id: 214,
            text: "물리치료",
            href: "/subject/temporomandibular-joint#row4",
          },
          {
            id: 215,
            text: "주사치료",
            href: "/subject/temporomandibular-joint#row5",
          },
        ],
      },
      {
        id: 22,
        text: "쁘띠",
        href: "/subject/petit",
        active: false,
        items: [
          {
            id: 221,
            text: "입가 주름치료",
            href: "/subject/petit#row1",
          },
          {
            id: 222,
            text: "입술 증대술",
            href: "/subject/petit#row2",
          },
          {
            id: 223,
            text: "팔자주름 치료",
            href: "/subject/petit#row3",
          },
          {
            id: 224,
            text: "입술 구각부 주름 치료",
            href: "/subject/petit#row4",
          },
          {
            id: 225,
            text: "뺨 증대술",
            href: "/subject/petit#row5",
          },
          {
            id: 226,
            text: "잇몸미소",
            href: "/subject/petit#row6",
          },
          {
            id: 227,
            text: "양쪽 교근비대증 치료",
            href: "/subject/petit#row7",
          },
        ],
      },
      {
        id: 23,
        text: "사랑니 등 고난이도 발치",
        href: "/subject/wisdom-tooth-extraction",
        active: false,
      },
      {
        id: 24,
        text: "임플란트 수술 및 보철",
        href: "/subject/implant-surgery-and-prosthetics",
        active: false,
        items: [
          {
            id: 241,
            text: "임플란트 치료의 특징",
            href: "/subject/implant-surgery-and-prosthetics#row1",
          },
          {
            id: 242,
            text: "일반적인 임플란트 치료 과정",
            href: "/subject/implant-surgery-and-prosthetics#row2",
          },
        ],
      },
      {
        id: 25,
        text: "임플란트 치료를 위한 뼈이식술",
        href: "/subject/implant-bone-graft",
        active: false,
        items: [
          {
            id: 251,
            text: "발치 후 뼈이식술",
            href: "/subject/implant-bone-graft#row1",
          },
          {
            id: 252,
            text: "치조골의 국소적 결손부 수복을 위한 뼈이식술",
            href: "/subject/implant-bone-graft#row2",
          },
          {
            id: 253,
            text: "치조골 증대술",
            href: "/subject/implant-bone-graft#row3",
          },
          {
            id: 254,
            text: "상악동골이식술",
            href: "/subject/implant-bone-graft#row4",
          },
        ],
      },
      {
        id: 26,
        text: "치과 소수술",
        href: "/subject/dental-minor-surgery",
        active: false,
        items: [
          {
            id: 261,
            text: "물혹(낭종)",
            href: "/subject/dental-minor-surgery#row1",
          },
          {
            id: 262,
            text: "치근단절제술/치근절단술",
            href: "/subject/dental-minor-surgery#row2",
          },
          {
            id: 263,
            text: "소대절제술",
            href: "/subject/dental-minor-surgery#row3",
          },
          {
            id: 264,
            text: "치조골성형술",
            href: "/subject/dental-minor-surgery#row4",
          },
          {
            id: 265,
            text: "보철 혹은 교정치료에 필요한 수술",
            href: "/subject/dental-minor-surgery#row5",
          },
          {
            id: 266,
            text: "치주치료",
            href: "/subject/dental-minor-surgery#row6",
          },
        ],
      },
      {
        id: 27,
        text: "치아 및 턱뼈 외상",
        href: "/subject/tooth-and-jaw-trauma",
        active: false,
      },
      {
        id: 28,
        text: "자연치를 보존하는 치료",
        href: "/subject/natural-tooth-preservation-treatment",
        active: false,
        items: [
          {
            id: 281,
            text: "근관치료",
            href: "/subject/natural-tooth-preservation-treatment#row1",
          },
          {
            id: 282,
            text: "레진 수복치료",
            href: "/subject/natural-tooth-preservation-treatment#row2",
          },
          {
            id: 283,
            text: "치아이식술과 치아재식술",
            href: "/subject/natural-tooth-preservation-treatment#row3",
          },
        ],
      },
      {
        id: 29,
        text: "예방치과치료",
        href: "/subject/preventive-dental-treatment",
        active: false,
        items: [
          {
            id: 291,
            text: "불소도포",
            href: "/subject/preventive-dental-treatment#row1",
          },
          {
            id: 292,
            text: "치아 홈 메우기",
            href: "/subject/preventive-dental-treatment#row2",
          },
          {
            id: 293,
            text: "구강위생관리 및 교육",
            href: "/subject/preventive-dental-treatment#row3",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    text: "채널K",
    href: "/channel-k",
    items: [
      {
        id: 31,
        text: "영상 콘텐츠",
        href: "/channel-k/video",
        active: false,
      },
      {
        id: 32,
        text: "블로그 포스트",
        href: "/channel-k/blog",
        active: false,
      },
      {
        id: 33,
        text: "치과 자료",
        href: "/channel-k/archive",
        active: false,
      },
      {
        id: 34,
        text: "자주묻는 질문",
        href: "/channel-k/faq",
        active: false,
      },
    ],
  },
  {
    id: 4,
    text: "진료시간 및 위치",
    href: "/contacts",
    active: false,
  },
];

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {},
});

export default menuSlice.reducer;
