import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SocialMedia = (props: any) => {
  const socialMediaItems = useSelector((state: any) => state.socialMedia);

  if (props.type === "icon") {
    return (
      <nav className={props.className}>
        <ul>
          {socialMediaItems.map((item: any) => (
            <li key={item.id}>
              <Link to={item.href} target="_blank">
                <img src={item.icon} alt={item.text} />
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    );
  } else if (props.type === "text") {
    return (
      <nav className={props.className}>
        <ul>
          {socialMediaItems.map((item: any) => (
            <li key={item.id}>
              <Link to={item.href} target="_blank">{item.text}</Link>
            </li>
          ))}
        </ul>
      </nav>
    );
  } else {
    return (
      <nav className="social-media">
        올바른 props.type을 기재해주세요. (컴포넌트 명: SocialMedia)
      </nav>
    );
  }
};

export default SocialMedia;
