import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInstance";

import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules';

// import HomeHeroDoctor from '../assets/images/home-hero-doctor.png';
import HomeHeroDesktop from '../assets/images/home-hero-desktop.jpg';
import HomeHeroMobile from '../assets/images/home-hero-mobile.jpg';
import HomeFigureRoom01 from '../assets/images/home-figure-room01.jpg';
import HomeFigureRoom02 from '../assets/images/home-figure-room02.jpg';
import HomeFigureRoom03 from '../assets/images/home-figure-room03.jpg';
import HomeFigureRoom04 from '../assets/images/home-figure-room04.jpg';
import HomeFigureRoom05 from '../assets/images/home-figure-room05.jpg';
import HomeFigureRoom06 from '../assets/images/home-figure-room06.jpg';
import HomeFigureRoom07 from '../assets/images/home-figure-room07.jpg';
import HomeFigureRoom08 from '../assets/images/home-figure-room08.jpg';
import HomeFigureRoom09 from '../assets/images/home-figure-room09.jpg';
import HomeFigureRoom10 from '../assets/images/home-figure-room10.jpg';
import HomeDirection from '../assets/images/home-direction.jpg';
import IconTooth from '../assets/images/icon-tooth.svg';
import IconClock from '../assets/images/icon-clock.svg';
import IconBooks from '../assets/images/icon-books.svg';

import ButtonArrow from "../components/ButtonArrow";
import Gallery from "../components/Gallery";
import JumbotronLink from "../components/JumbotronLink";
import Banner from "../components/Banner";
import Horizontal from "../components/Horizontal";
import Popup from "../components/Popup";

interface ApiResponse {
  meta: {
    limit: number;
    perview: number;
    page: number;
    total: number;
  };
  items: [{
    id: number;
    board_id: number;
    title: string;
    content: string;
    attach: number;
    thumb: number;
    youtube: string;
    writer: number;
    date: string;
    board_name: string;
    thumb_path: string;
    writer_nickname: string;
  }];
}

function Home() {
  const navigate = useNavigate();
  const [galleryData, setGalleryData] = useState<ApiResponse | null>(null);

  useEffect(() => {
    // API에서 데이터 불러오기
    axiosInstance.post(`${process.env.REACT_APP_API_BASE_URL}/board/post/list/1`, {
        limit: 5,
        board_id: [1, 2],
      })
      .then(response => setGalleryData(response.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <>
      <Popup />
      <Container>
        {/*
        <section className="home-hero">
          <h2>더 나은 결과를 만들 수 있도록 <br />치료의 <strong className="text-primary">목적</strong>과 <strong className="text-primary">과정</strong>에 최선을 다합니다.</h2>
          <figure>
            <img src={HomeHeroDoctor} alt="김영균 대표원장 사진" />
            <figcaption>김영균 대표원장</figcaption>
          </figure>
        </section>
        */}
        <section className="home-imghero">
          <div className="home-imghero-imgbox desktop">
            <img src={HomeHeroDesktop} alt="더 나은 결과를 만들 수 있도록 치료의 목적과 과정에 최선을 다합니다" />
          </div>
          <div className="home-imghero-imgbox mobile">
            <img src={HomeHeroMobile} alt="더 나은 결과를 만들 수 있도록 치료의 목적과 과정에 최선을 다합니다" />
          </div>
        </section>

        <Horizontal />

        <section className="home-philosophy">
          <Row>
            <Col className="home-philosophy-title" xs={12} lg={6} xl={5}>
              <h2>케이 구강악안면외과 치과의원은 <br />환자와 직원들의 건강을 생각하면서 편안하고 쾌적한 진료실 환경을 중시합니다.</h2>
              <p>감염, 환경, 안전관리를 중시합니다.<br />최고의 소독 및 멸균 시스템, 공기정화 및 청정 시스템, 응급상황을 대비한 안전시스템을 완벽히 구축 하였습니다.</p>
            </Col>
            <Col className="home-philosophy-bg" xs={12} lg={6} xl={7}></Col>
          </Row>
        </section>

        <Horizontal bottom="40px" />
        
        <section className="home-info">
          <Row className="vertical-wrap flex-wrap-xs flex-nowrap-lg">
            <Col className="home-info-title" xs={12} lg={6}>
              <sup>케이 구강악안면외과 치과의원 대표원장 소개</sup>
              <h2>오랜 경력과 실력을 자랑하는 <br />케이 구강악안면외과 치과의원 <br />김영균 대표원장은 어떤 사람인가?</h2>
              <div>
                <ButtonArrow href="/doctor" text="더 알아보기" />
              </div>
            </Col>
            <Col className="vertical"></Col>
            <Col className="home-info-deflist" xs={12} lg={6}>
              <div>
                <ul>
                  <li onClick={() => navigate("/about")}>
                    <img src={IconTooth} alt="치아 아이콘" />
                    <h3>치과 소개</h3>
                    <p>성남시 분당구 오리역에 위치한 케이구강악안면외과치과의원은 환자가 가장 불편해 하는 것을 최우선적으로 해결하기 위해 노력할 것입니다. 우리가 해결하기 어려운 진료는 상급의료기관이나 근처의 유능한 동료 치과의사들에게 의뢰하는 시스템을 준수할 것 입니다.</p>
                  </li>
                  <li onClick={() => navigate("/contacts")}>
                    <img src={IconClock} alt="시계 아이콘" />
                    <h3>진료 시간 안내</h3>
                    <ol>
                      <li>월, 화, 수, 목, 금 (평일 진료) : 9:00 ~ 18:00</li>
                      <li>점심시간 : 13:00 ~ 14:00(1시간)</li>
                    </ol>
                  </li>
                  <li onClick={() => navigate("/subject")}>
                    <img src={IconBooks} alt="책 아이콘" />
                    <h3>진료과목</h3>
                    <p>턱관절질환, 사랑니 등 어려운 발치, 임플란트, 입가주름 치료, 잇몸치료, 예방진료, 자연치 살리기 등 다양한 진료분야를 소개합니다.</p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </section>
        
        <Horizontal top="40px" bottom="40px" />

        <section className="home-figure">
          
          <Swiper
            modules={[Autoplay]}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop={true}
            slidesPerView={1}
            spaceBetween={16}
            breakpoints={{
              768: {
                slidesPerView: 2,
                spaceBetween: 16
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 24
              },
              1260: {
                slidesPerView: 4,
                spaceBetween: 32
              }
            }}
          >
            <SwiperSlide>
              <figure>
                <div><img src={HomeFigureRoom01} alt="대기실 사진" /></div>
                <figcaption>대기실</figcaption>
              </figure>
            </SwiperSlide>
            <SwiperSlide>
              <figure>
                <div><img src={HomeFigureRoom02} alt="대기실 사진" /></div>
                <figcaption>대기실</figcaption>
              </figure>
            </SwiperSlide>
            <SwiperSlide>
              <figure>
                <div><img src={HomeFigureRoom03} alt="대기실 사진" /></div>
                <figcaption>대기실</figcaption>
              </figure>
            </SwiperSlide>
            <SwiperSlide>
              <figure>
                <div><img src={HomeFigureRoom04} alt="파우더룸 사진" /></div>
                <figcaption>파우더룸</figcaption>
              </figure>
            </SwiperSlide>
            <SwiperSlide>
              <figure>
                <div><img src={HomeFigureRoom05} alt="상담실 사진" /></div>
                <figcaption>상담실</figcaption>
              </figure>
            </SwiperSlide>
            <SwiperSlide>
              <figure>
                <div><img src={HomeFigureRoom06} alt="CT실 사진" /></div>
                <figcaption>CT실</figcaption>
              </figure>
            </SwiperSlide>
            <SwiperSlide>
              <figure>
                <div><img src={HomeFigureRoom07} alt="소독실 사진" /></div>
                <figcaption>소독실</figcaption>
              </figure>
            </SwiperSlide>
            <SwiperSlide>
              <figure>
                <div><img src={HomeFigureRoom08} alt="진료실 사진" /></div>
                <figcaption>진료실</figcaption>
              </figure>
            </SwiperSlide>
            <SwiperSlide>
              <figure>
                <div><img src={HomeFigureRoom09} alt="진료실 사진" /></div>
                <figcaption>진료실</figcaption>
              </figure>
            </SwiperSlide>
            <SwiperSlide>
              <figure>
                <div><img src={HomeFigureRoom10} alt="원장실 사진" /></div>
                <figcaption>원장실</figcaption>
              </figure>
            </SwiperSlide>
          </Swiper>
        </section>
        
        <Horizontal top="40px" />

        <Banner className="home-banner" />
        
        <Horizontal bottom="40px" />

        <section className="home-direction">
          <Row className="vertical-wrap flex-wrap-xs flex-nowrap-lg">
            <Col className="home-direction-title" xs={12} lg={6} xl={4}>
              <h2>위치 안내</h2>
              <dl>
                <dt>주소</dt>
                <dd>경기도 성남시 분당구 구미로 11, 5층<br />(오리역 3번출구 도보 5분)</dd>

                <dt>주차정보</dt>
                <dd>건물 지하에 주차장이 비치되어 있습니다.<br />주차장이 혼잡할 경우 근처 상가 주차장을 이용하시면 1시간 주차요금을 지원해 드리겠습니다.</dd>
              </dl>
            </Col>
            <Col className="vertical"></Col>
            <Col className="home-direction-content" xs={12} lg={6} xl={8}>
              <div className="iframe">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3172.17295180785!2d127.10799817720476!3d37.33841393717383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b59e94e510ed1%3A0xd4f2160af179d525!2z6rK96riw64-EIOyEseuCqOyLnCDrtoTri7nqtawg6rWs66-466GcIDEx!5e0!3m2!1sko!2skr!4v1700212067871!5m2!1sko!2skr" width="100%" height="100%" title="구글지도"></iframe>
              </div>
              <div className="image mt-3">
                <img src={HomeDirection} alt="케이구강악안면외과 치과의원 외부 전경" />
              </div>
            </Col>
          </Row>
        </section>
        
        <Horizontal top="40px" bottom="40px" />

        <section className="home-channelk">
          <Row className="vertical-wrap flex-wrap-xs flex-nowrap-lg">
            <Col className="home-channelk-title" xs={12} lg={6} xl={4}>
              <h2>CHANNEL.K</h2>
              <h3>치과의 궁금증은 이곳에서</h3>
              <p>치과 질병에 대한 지식, 진료과정, 치료과정, 환자들이 꼭 알아야할 상식 등 다양한 정보를 전달합니다.</p>
            </Col>
            <Col className="vertical"></Col>
            <Col className="home-channelk-content" xs={12} lg={6} xl={8}>
              <Gallery items={galleryData?.items} col={2} firstEmphasis={true} />
            </Col>
          </Row>
        </section>

        <Horizontal top="40px" />

        <JumbotronLink data={[
            {
              key: 0,
              text: "031-713-2879",
              href: "tel: 031-713-2879",
              target: "_self",
            },
          ]}
        >
          <h2 className="title">진료 예약 및 상담은 언제나 열려있습니다.</h2>
          <div className="text">(진료시간 : 평일 9시 ~ 6시까지)</div>
        </JumbotronLink>
      </Container>
    </>
  );
}

export default Home;
