import React from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "react-bootstrap";

import Horizontal from "../components/Horizontal";
import NavigationSubject from "../components/NavigationSubject";

import NaturalToothPreservationTreatment01 from "../assets/images/natural-tooth-preservation-treatment01.jpg";
import NaturalToothPreservationTreatment02 from "../assets/images/natural-tooth-preservation-treatment02.jpg";

function NaturalToothPreservationTreatment() {
  return (
    <>
      <Helmet>
        <title>케이 구강악안면외과 치과의원 - 진료과목 - 자연치를 보존하는 치료</title>
      </Helmet>

      <div className="subject">
        <Container>
          <h1>자연치를 보존하는 치료</h1>

          <Horizontal top="40px" bottom="40px" />

          <div>
            <h2>근관치료 (신경치료)</h2>
            <p>충치나 치주질환에 의해 치아의 신경이 감염될 경우 감염된 신경을 제거하고 빈 공간을 생체적합성이 있는 특수재료로 채우는 치료법입니다. 통상적으로 여러 번 내원해서 치료를 받으셔야 하지만 경우에 따라 1회에 치료를 끝내거나 수술적인 방법으로 신속히 치료를 완료하기도 합니다. 다음과 같은 경우엔 근관치료를 하게 됩니다.</p>
            <ol className="list-decimal">
              <li>비가역성 치수염: 찬물에 매우 아프고 통증이 오래 지속되는 경우</li>
              <li>비가역성 치수염 말기: 찬물에는 오히려 통증이 감소하며 뜨거운 물에 매우 아프며 밤에 잠을 자기 힘들 정도의 통증을 호소합니다.</li>
              <li>치수 괴사: 감염된 치아신경이 썪으면서 방사선사진상 치근단의 골흡수가 관찰되며, 오래되면 잇몸으로 고름이 배출되는 구멍(누공)이 형성됩니다. 대부분의 경우에 환자는 통증을 호소하지 않는 경우가 많습니다.</li>
              <li>보철치료를 위해 정상 치수임에도 근관치료를 해야 하는 경우: 정출된 치아나 전치 심미 수복의 경우에 치아 삭제 후 치아 신경이 노출되는 경우가 매우 많습니다. 이런 경우엔 미리 근관치료를 하게 됩니다.</li>
              <li>처음 신경치료를 받았던 치아가 재발되었거나 치아 신경관이 막히면서 통상적인 방법으로 근관치료를 하기 어려운 경우엔 외과적인 방법이 수행되기도 합니다.</li>
            </ol>
            <div>
              <img className="w-100" src={NaturalToothPreservationTreatment01} alt="근관치료(신경치료)" />
            </div>
          </div>
          <Horizontal top="40px" bottom="40px" />

          <div id="row2">
            <h2>레진 수복치료</h2>
            <p>레진 치료의 가장 큰 장점은 ‘치아를 적게 삭제’하는 것입니다. 최근 들어 환자들의 의식 수준이 높아지면서 가능한 한 치아 삭제를 적게 하려는 경향이 있으므로 앞으로 레진 치료의 수요는 점점 더 늘어날 것입니다. 레진은 치과에서 수복(때우는 치료)이나 접착을 위해 사용되는 합성수지로 세라믹(도재)과 더불어 심미치료를 위해 가장 많이 사용되는 재료 중 하나입니다. 치아에 충치, 파절, 마모 등이 발생하여 경조직이 손상된 경우, 충치를 제거하거나 파절 및 마모면을 다듬고 치아의 자연색과 유사한 레진을 이용해 수복합니다.</p>
          </div>

          <Horizontal top="40px" bottom="40px" />

          <Row id="row3">
            <Col className="d-flex flex-column justify-content-between" xs="12" xl="8" xxl="9">
              <div>
                <h2>치아이식술과 치아재식술</h2>
                <p>치아이식술은 치아가 상실된 부위에 구강 내의 다른 치아들(일반적으로 사랑니)을 발치하여 이식하는 술식을 의미합니다. 치아재식술은 외상으로 인해 탈구된 치아나 비정상적인 부위에 위치하는 치아들 또는 근관치료나 수복치료가 불가능하거나 매우 어려워서 의도적으로 치아를 발치한 후 구강 외에서 치료하여 원위치로 복위시킬 목적으로 사용됩니다.이 수술의 목적은 발치 전 치아를 보존하기 위한 최종 치료법입니다. 치료가 중단되거나 실패할 경우에는 통상적인 보철치료 또는 임플란트 치료가 필요할 것입니다. 실패를 감수하고 최대한 치아를 보존하고 싶은 욕구가 강한 환자분들이 선택할 수 있는 최종 치료법임을 잘 이해하시고 동의하셔야 합니다.</p>
              </div>
              <div className="d-none-xs d-block-xl text-end">
                뼈 속에 묻혀있던 송곳니를 발치한 후 남아있던 유치를 발치하고 그 위치로 이동시켜 재식한증례 &gt;
              </div>
            </Col>
            <Col className="mt-4" xs="12" xl="4" xxl="3">
              <figure className="position-relative mb-0 text-center">
                <img className="mw-100" src={NaturalToothPreservationTreatment02} alt="뼈 속에 묻혀있던 송곳니를 발치한 후 남아있던 유치를 발치하고 그 위치로 이동시켜 재식한증례" />
                <figcaption className="d-block-xs d-none-xl mt-3">뼈 속에 묻혀있던 송곳니를 발치한 후 남아있던 유치를 발치하고 그 위치로 이동시켜 재식한증례</figcaption>
              </figure>
            </Col>
          </Row>

          <Horizontal top="40px" bottom="40px" />

          <NavigationSubject />
        </Container>
      </div>
    </>
  );
}

export default NaturalToothPreservationTreatment;
