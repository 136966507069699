import { configureStore } from "@reduxjs/toolkit";

import menuReducer from "./store/menuSlice";
import socialMediaReducer from "./store/socialMediaSlice";
import menuFooterReducer from "./store/menuFooterSlice";
import hamburgerReducer from "./store/hamburgerSlice";

const store = configureStore({
  reducer: {
    menu: menuReducer,
    socialMedia: socialMediaReducer,
    menuFooter: menuFooterReducer,
    hamburger: hamburgerReducer,
  },
});

export default store;
