import React from "react";

import NavigationFooter from "./NavigationFooter";
import FooterLogo from '../assets/images/l-footer-logo.svg';

import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();

  return (
    <footer className="l-footer">
      <Container className="is-full">
        {/* Footer Logo */}
        <div className="l-footer-logo"><img src={FooterLogo} alt="케이구강악안면외과 치과의원 로고" /></div>

        {/* Footer Copyright */}
        <div className="l-footer-copyright">
          <address>
            <dl>
              <dt>대표자명</dt>
              <dd>김영균</dd>

              <dt>사업자번호</dt>
              <dd>369-73-00484</dd>

              <dt>주소</dt>
              <dd>경기 성남시 분당구 구미로 11 포인트타운빌딩, 5층</dd>

              <dt>연락처</dt>
              <dd>031-713-2879</dd>
            </dl>
            <p>Copyright ©️ 2023 K DENTAL CLINIC All right reserved.</p>
          </address>
        </div>

        {/* Navigation(Footer) */}
        <NavigationFooter className="l-footer-nav" />
      </Container>
    </footer>
  );
}

export default Footer;
