import React from 'react';

const Horizontal = ({ top, right, bottom, left, className }: any) => {
  const hrStyle = {
    marginTop: top ? top : null,
    marginRight: right ? right : null,
    marginBottom: bottom ? bottom : null,
    marginLeft: left ? left : null,
  };

  return <hr className={className ? "horizontal " + className : "horizontal"} style={hrStyle} />;
};

export default Horizontal;