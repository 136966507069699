import React from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "react-bootstrap";

import Horizontal from "../components/Horizontal";
import NavigationSubject from "../components/NavigationSubject";

import WisdomToothExtraction01 from '../assets/images/wisdom-tooth-extraction01.jpg';
import WisdomToothExtraction02 from '../assets/images/wisdom-tooth-extraction02.png';
import WisdomToothExtraction03 from '../assets/images/wisdom-tooth-extraction03.png';
import WisdomToothExtraction04 from '../assets/images/wisdom-tooth-extraction04.png';
import WisdomToothExtraction05 from '../assets/images/wisdom-tooth-extraction05.png';
import WisdomToothExtraction06 from '../assets/images/wisdom-tooth-extraction06.png';

function WisdomToothExtraction() {
  return (
    <>
      <Helmet>
        <title>케이 구강악안면외과 치과의원 - 진료과목 - 사랑니 등 어려운 발치</title>
      </Helmet>

      <div className="subject">
        <Container>
          <Row>
            <Col xs="12" lg="6" xl="7">
              <h1>사랑니 등 어려운 발치</h1>
              <p className="mt-auto mb-3">사랑니가 턱뼈에 묻혀있는 경우 주변에 물혹이나 종양성 병소가 생길 수 있으며 사랑니 상방의 연조직의 염증이 지속되면서 심한 통증을 유발하기도 합니다. 또한 인접한 치아들의 충치 혹은 잇몸염증을 심하게 하거나 부정교합을 유발함으로 인해 발치가 필요한 경우가 많습니다. 또한 치아들이 비정상적인 위치에 있거나 기형으로 인해 쉽게 발치하기 어려울 수도 있습니다. 이러한 경우엔 구강악안면외과 전문의들이 환자의 고통을 최소화하면서 쉽게 발치할 수 있습니다.</p>
            </Col>
            <Col xs="12" lg="6" xl="5">
              <img className="w-100" src={WisdomToothExtraction01} alt="사랑니 등 어려운 발치" />
            </Col>
          </Row>
          <div className="bg-light p-4 mt-5">
            <Row className="d-none-xs d-flex-xl m-0">
              <Col className="p-4">
                <img className="w-100" src={WisdomToothExtraction02} alt="A 유형" />
              </Col>
              <Col className="p-4">
                <img className="w-100" src={WisdomToothExtraction03} alt="B 유형" />
              </Col>
              <Col className="p-4">
                <img className="w-100" src={WisdomToothExtraction04} alt="C 유형" />
              </Col>
              <Col className="p-4">
                <img className="w-100" src={WisdomToothExtraction05} alt="D 유형" />
              </Col>
              <Col className="p-4">
                <img className="w-100" src={WisdomToothExtraction06} alt="E 유형" />
              </Col>
            </Row>
            <Row className="d-flex-xs d-none-xl m-0 justify-content-center">
              <Col className="p-3" xs="6" md="4">
                <img className="w-100" src={WisdomToothExtraction02} alt="A 유형" />
              </Col>
              <Col className="p-3" xs="6" md="4">
                <img className="w-100" src={WisdomToothExtraction03} alt="B 유형" />
              </Col>
              <Col className="p-3" xs="6" md="4">
                <img className="w-100" src={WisdomToothExtraction04} alt="C 유형" />
              </Col>
              <Col className="p-3" xs="6" md="4">
                <img className="w-100" src={WisdomToothExtraction05} alt="D 유형" />
              </Col>
              <Col className="p-3" xs="6" md="4">
                <img className="w-100" src={WisdomToothExtraction06} alt="E 유형" />
              </Col>
            </Row>
          </div>

          <Horizontal top="40px" bottom="40px" />

          <NavigationSubject />
        </Container>
      </div>
    </>
  );
}

export default WisdomToothExtraction;
