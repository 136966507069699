import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axiosInstance from "../axiosInstance";

import { Container, ListGroup, Nav } from "react-bootstrap";

import NavigationChannelK from "../components/NavigationChannelK";
import Gallery from "../components/Gallery";
import Banner from "../components/Banner";
import Horizontal from "../components/Horizontal";
import JumbotronLink from "../components/JumbotronLink";
import Numbering from "../components/Numbering";

interface ApiResponse {
  meta: {
    limit: number;
    perview: number;
    page: number;
    total: number;
  };
  items: [{
    id: number;
    board_id: number;
    title: string;
    content: string;
    attach: number;
    thumb: number;
    youtube: string;
    writer: number;
    date: string;
    board_name: string;
    thumb_path: string;
    writer_nickname: string;
  }];
}

interface BoardApiResponse {
  id: number;
  name: string;
  role_read: number;
  role_form: number;
  role_read_name: string;
  role_form_name: string;
  category: string[];
}

function ListGallery() {
  const location = useLocation();
  const navigate = useNavigate();
  const q = new URLSearchParams(location.search).get("q") as string;
  const category = new URLSearchParams(location.search).get("category") as string;
  const { page } = useParams();
  const [galleryData, setGalleryData] = useState<ApiResponse | any>(null);
  const [boardData, setBoardData] = useState<BoardApiResponse | any>(null);

  useEffect(() => {
    // 라우트가 현재 치과 자료 일 때 API로 보낼 파라미터
    if (location.pathname.startsWith("/channel-k/video")) {
      axiosInstance.post(`${process.env.REACT_APP_API_BASE_URL}/board/post/list/${page}`, {
        limit: 12,
        board_id: [1],
        keyword: q && q != "" ? q : null,
        category: category,
      })
        .then(response => setGalleryData(response.data))
        .catch(error => console.error('Error fetching data:', error));

      // 게시판 정보 API 호출
      axiosInstance.get(`${process.env.REACT_APP_API_BASE_URL}/board/manage/read/1`)
        .then(response => setBoardData(response.data))
        .catch(error => console.error('Error fetching data:', error));
    }
    // 라우트가 현재 자주묻는 질문 일 때 API로 보낼 파라미터
    else if (location.pathname.startsWith("/channel-k/blog")) {
      axiosInstance.post(`${process.env.REACT_APP_API_BASE_URL}/board/post/list/${page}`, {
        limit: 12,
        board_id: [2],
        keyword: q && q != "" ? q : null,
        category: category,
      })
        .then(response => setGalleryData(response.data))
        .catch(error => console.error('Error fetching data:', error));

      // 게시판 정보 API 호출
      axiosInstance.get(`${process.env.REACT_APP_API_BASE_URL}/board/manage/read/2`)
        .then(response => setBoardData(response.data))
        .catch(error => console.error('Error fetching data:', error));
    }
  },[location]);

  const handleCategoryButtonClick = (value?: string) => {
    const searchParams = new URLSearchParams(location.search);
    if (value) {
      searchParams.set('category', value);
    } else {
      searchParams.delete('category');
    }

    // 다른 쿼리 파라미터는 건들지 않았을 경우를 위해 기존 쿼리 파라미터를 유지합니다.
    const updatedParams = searchParams.toString();

    if (location.pathname.startsWith("/channel-k/video")) {
      navigate(`/channel-k/video/1?${updatedParams}`);
    } else if (location.pathname.startsWith("/channel-k/blog")) {
      navigate(`/channel-k/blog/1?${updatedParams}`);
    }
  };

  return (
    <>
      <Helmet>
        <title>케이 구강악안면외과 치과의원 - 채널K - 블로그 포스트</title>
      </Helmet>

      <Container className="channelk">

        {/* Navigation(Channel-K) */}
        <NavigationChannelK />

        <Horizontal bottom="10px" />

        {/* Category Tab */}
        {boardData?.category.length > 0 && (
          <Nav className="nav-default mt-5" justify variant="tabs">
            <Nav.Item>
              <Nav.Link
                className={!category ? "active" : ""}
                onClick={() => handleCategoryButtonClick()}
              >
                전체
              </Nav.Link>
            </Nav.Item>

            {boardData?.category.map((item: any) => (
              <Nav.Item key={item}>
                <Nav.Link
                  className={category === item ? "active" : ""}
                  onClick={() => handleCategoryButtonClick(item)}
                >
                  {item}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        )}

        {galleryData && (
          <Gallery items={galleryData.items} col={3} />
        )}
        
        <Horizontal top="40px" bottom="0" />
        <Banner />
        <Horizontal top="0" bottom="40px" />

        <Numbering href={"/channel-k/" + (location.pathname.startsWith("/channel-k/video") ? "video" : "blog") + "/list/{number}"} limit={galleryData?.meta.limit} perview={galleryData?.meta.perview} page={galleryData?.meta.page} total={galleryData?.meta.total} />

        <Horizontal top="40px" bottom="40px" />

        <JumbotronLink data={[
            {
              key: 0,
              text: "네이버 예약",
              href: "https://booking.naver.com/booking/13/bizes/1089335",
              target: "_blank",
            },
          ]}
        >
          <h2 className="title">진료 예약 및 상담</h2>
        </JumbotronLink>

      </Container>
    </>
  );
}

export default ListGallery;
