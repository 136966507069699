import { createSlice } from "@reduxjs/toolkit";

const initialState = [
  {
    id: 0,
    text: "유튜브",
    href: "https://www.youtube.com/channel/UCgWjHvPf6WyrZVJPXLsyCrw",
  },
  {
    id: 1,
    text: "네이버 블로그",
    href: "https://blog.naver.com/youngkyun_",
  }
];

const menuFooterSlice = createSlice({
  name: "menuFooter",
  initialState,
  reducers: {},
});

export default menuFooterSlice.reducer;
